import React from "react";
import { Link } from "react-router-dom";

var style = {
  backgroundColor: "#000",
  opacity: "0.85",
  padding: "17px",
  position: "fixed",
  bottom: "0",
  left: "0",
  height: "40px",
  width: "100%",
  color: "white",
  zIndex: 1000,
};
var c = {
  fontFamily: 'Lato,Helvetica Neue,Helvetica,Arial,sans-serif',
  color: "rgba(255, 254, 254, 0.75)",
  marginTop: "-5px",
};

var D = {
  fontFamily: 'Lato,Helvetica Neue,Helvetica,Arial,sans-serif',
  color: "rgba(255, 254, 254, 0.75)",
  marginTop: "-20px",
  textAlign: "right",
  maginRight: "0px",
};

var A = {
  color: "rgba(255, 254, 254, 0.75)",
  textDecoration: "none",
};

const TABLET_BREAKPOINT = 700;
const MOBILE_BREAKPOINT = 450;

function Footer(props) {
  const { size } = props;
  const CURRENT_YEAR = new Date().getFullYear();
  const START_YEAR = '2021';

  return (
    <div>
      <div style={style}>
        <div style={c}>
          {size[0] > MOBILE_BREAKPOINT ? `©${START_YEAR}-${CURRENT_YEAR} Crown Copyright` : `©${CURRENT_YEAR}`}</div>
        <div style={D}>
          <a style={A} href="https://www.iomttraces.com/legal/cookie-policy">{size[0] > TABLET_BREAKPOINT ? 'Cookie Policy' : 'Cookies'}</a>
          {" / "}
          <a style={A} href=" https://www.iomttraces.com/legal/privacy-policy">{size[0] > TABLET_BREAKPOINT ? 'Privacy Policy' : 'Privacy'}</a>
          {" / "} 
          <Link style={A} to={{
            pathname: "/contactForm",
          }}>
            {size[0] > TABLET_BREAKPOINT ? "Contact Form" : size[0] > MOBILE_BREAKPOINT ? "Contact us" : "Contact"}
          </Link>
          {" / "} 
          <Link style={A} to={{
            pathname: "/helpDesk",
          }}>
            {size[0] > TABLET_BREAKPOINT ? "Helpdesk Information" : "Help"}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Footer;
