import React from "react";
import { SignIn as AmplifySignIn } from "aws-amplify-react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import bike from "../assets/images/bike.png";
import BtnLink from "../common/BtnLink";
import AppTitle from "../common/AppTitle";
import Translate from "../common/Translate";
import { initLanguage } from "../common/language";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API } from "aws-amplify";
import { currentUser } from "../common/helpers";
import { getTheme } from "../theme";
import { AppContext } from "../AppProvider";
import ErrorToast from "../common/ErrorToast";
import { Auth } from "aws-amplify";
import EnTranslate from "../i18n/en";

const useStyles = themeSecond => ({
  root: {
    "& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
      boxShadow: "0 0 0 30px #242a37 inset !important",
      "-webkit-box-shadow": "0 0 0 30px #242a37 inset !important",
      "-webkit-text-fill-color": "#fff !important"
    },
    height: "100vh",
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.3)"
    },
    '& label, & label[data-shrink = "true"]': {
      color: "#7F8FA4",
      fontSize: "16px",
      fontFamily: "MuseoSans500"
    }
  },
  image: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)),url(${bike})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: "25px",
    display: "flex",
    flexDirection: "column",
    background: "#242A37",
    color: "#fff",
    position: "relative"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: themeSecond.spacing(1),
    color: "#fff",
    "& input": {
      color: "#fff"
    }
  },
  submit: {
    margin: themeSecond.spacing(6, 0, 1),
    fontSize: "24px"
  },
  forgotPass: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    },
    marginTop: "8px"
  },
  createAcc: {
    color: "#fa667c"
  },
  bgStyle: {
    background: "#181C28 !important",
  }
});

let FINAL_THEME;
const BREAKPOINT_1 = 1700;
const BREAKPOINT_2 = 1300;
const BREAKPOINT_3 = 450;

export class SignIn extends AmplifySignIn {
  constructor(props) {
    super(props);
    initLanguage();
    FINAL_THEME = createMuiTheme(getTheme("#FFFFFF"));
    this.state = {
      username: "",
      password: "",
      error: "",
      loading: false,
      size: [0, 0]
    };
    this.setSize = this.setSize.bind(this);
    this.goToConfirmation = this.goToConfirmation.bind(this);
  }

  setSize() {
    this.setState(prev => {
      return (
        {
          ...prev,
          size: [window.innerWidth, window.innerHeight],
        }
      );
    });
  }

  goToConfirmation() {
    this.props.onStateChange("confirmSignUp", this.state.us);
  }

  componentDidMount() {
    this.setSize();
    window.addEventListener('resize', this.setSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize);
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.signIn();
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.authState === "signedIn") {
      API.get("IOM", `/accounts/${currentUser().id}`)
        .then(res => {
          res.data.attributes &&
            localStorage.setItem(
              "tid",
              res.data.attributes.personal_data.default_team_id
            );
        })
        .catch(error => {
          console.log("post error: ", error, error.response);
        });
    }
    if (
      this.props.authState !== prevProps.authState &&
      this.props.authState === "signIn"
    ) {
      this.setState({ error: "" });
    }
  }

  login = (username, password) => {
    this.setState({ loading: true });
    Auth.signIn({
      username,
      password
    })
      .then(user => {
        this.setState({ loading: false });
        if(Object.hasOwn(user, 'challengeParam') && 
          Object.hasOwn(user.challengeParam, "userAttributes") && 
          user.challengeParam.userAttributes.email_verified === "false") {
          this.setState({error: EnTranslate['user_not_confirmed']})
        }
        else if(user.attributes.email_verified === false) {
          this.setState({error: EnTranslate['user_not_confirmed']});
        } else {
          this.props.onStateChange("signedIn")
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.setState({ error: err.code === 'UserNotConfirmedException' ? EnTranslate['user_not_confirmed'] : err || err.log });
      });
  };

  makeResponsive(switcher) {
    switch (switcher) {
      case 'form_container': return this.state.size[0] > BREAKPOINT_1 ?
        { padding: '130px 80px 130px 80px' } :
        this.state.size[0] > BREAKPOINT_2 ?
          { padding: '130px 40px 130px 40px' } :
          this.state.size[0] > BREAKPOINT_3 ?
            { padding: '130px 10px 130px 10px' } :
            { padding: '20px 0px 20px 0px' };
      case 'paper': return this.state.size[0] > BREAKPOINT_2 ?
        { padding: '80px 80px 80px 80px' } :
        this.state.size[0] > BREAKPOINT_3 ?
          { padding: '80px 50px 80px 50px' } :
          { padding: '80px 20px 80px 20px', margin: '20px 5px 20px 5px' };
      default: {
        console.error('Error: Wrong value provided to makeResponsive() function !');
        return {};
      }
    }
  }

  showComponent() {
    const { classes } = this.props;

    return (
      <AppContext.Consumer>
        {context => (
          <Grid container component="main" className={classes.root}>
            <ErrorToast error={this.state.error} />
            <CssBaseline />
            <Grid item xs={false} md={6} className={classes.image} />
            <Grid item xs={12} md={6} component={Paper} elevation={6} square className={classes.bgStyle} style={this.makeResponsive('form_container')}>
              {/*to do : wrap the app into MuiThemeProvider*/}
              <MuiThemeProvider
                theme={
                  context.state.brandingColor
                    ? createMuiTheme(getTheme(context.state.brandingColor))
                    : createMuiTheme(getTheme("#FFFFFF"))
                }
              >
                <AppTitle big />
                <div className={classes.paper} style={this.makeResponsive('paper')}>
                  <Typography variant="h1">
                    <Translate i18nKey="login_to_your_account" />
                  </Typography>
                  <form className={classes.form} /*onSubmit={this.signIn}*/>
                    <TextField
                      fullWidth
                      margin="normal"
                      required
                      id="username"
                      key="username"
                      name="username"
                      onChange={e => {
                        this.handleInputChange(e);
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                      onKeyPress={this.handleKeyPress}
                      label={<Translate i18nKey="username" />}
                      autoComplete="username"
                      autoFocus
                    />
                    <TextField
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      required
                      label={<Translate i18nKey="password" />}
                      type="password"
                      id="password"
                      key="password"
                      name="password"
                      onChange={e => {
                        this.handleInputChange(e);
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                      onKeyPress={this.handleKeyPress}
                      autoComplete="current-password"
                    />
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        style={{
                          display: "inline-block",
                          fontSize: "1rem",
                        }}
                      >
                        <Translate i18nKey="forgot_your_password?" />
                      </Typography>
                      <BtnLink
                        click={() => this.changeState("forgotPassword")}
                        label={<Translate i18nKey="reset_password" />}
                      />
                    </Grid>
                    <Button
                      //type="submit"
                      onClick={() =>
                        this.login(this.state.username, this.state.password)
                      }
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {/* the state is set by the original Amplify SingIn component, it is only created once the user clicks on the signin button */}
                      {this.state.loading ? (
                        <CircularProgress
                          style={{ color: FINAL_THEME.palette.common.white }}
                        />
                      ) : (
                          <Translate i18nKey="sign_in" />
                        )}
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Typography
                          variant="body2"
                          style={{
                            display: "inline-block",
                            fontSize: "1rem",
                          }}
                        >
                          <Translate i18nKey="no_account?" />
                        </Typography>
                        <BtnLink
                          click={() => this.changeState("signUp")}
                          label={<Translate i18nKey="create_account" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          style={{
                            display: "inline-block",
                            fontSize: "1rem",
                          }}
                        >
                          <Translate i18nKey="got_a_confirmation_code" />?
                        </Typography>
                        <BtnLink
                          click={this.goToConfirmation}
                          label={<Translate i18nKey="confirm_sign_up" />}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </MuiThemeProvider>
            </Grid>
          </Grid>
        )}
      </AppContext.Consumer>
    );
  }
}

export default withStyles(useStyles)(SignIn);
