import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "ProximaBold",
    textAlign: "center",
    color: "#e1e3ea"
  },
  big: {
    fontSize: 24
  }
}));

const AppTitle = props => {
  const classes = useStyles();
  const { title } = props;

  return (
    <Typography
      id={props.id}
      style={props.style}
      className={clsx(classes.root, props.className, {
        [classes.big]: props.big
      })}
    >
      {title}
    </Typography>
  );
};

export default AppTitle;
