import React from "react";
import { Auth } from "aws-amplify";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import AppTitle from "../common/AppTitle";
import BtnLink from "../common/BtnLink";
import Translate from "../common/Translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorToast from "../common/ErrorToast";
import { AppContext } from "../AppProvider";
import { getTheme } from "../theme";
import { createMuiTheme } from "@material-ui/core/styles";

const useStyles = (theme) => ({
  root: {
    display: "block",
    "& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
      boxShadow: "0 0 0 30px #242a37 inset !important",
      "-webkit-box-shadow": "0 0 0 30px #242a37 inset !important",
      "-webkit-text-fill-color": "#fff !important",
    },
    height: "100vh",
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.3)",
    },
    '& label, & label[data-shrink = "true"]': {
      color: "#7F8FA4",
      fontSize: "16px",
      fontFamily: "MuseoSans500",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    background: "#242A37",
    color: "#fff",
    maxWidth: "600px",
  },
  title: {
    marginBottom: theme.spacing(2.75),
  },
  subTitle: {
    paddingBottom: theme.spacing(3),
  },
  forgotPass: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bgStyle: {
    background: "#181C28 !important",
  },
  externalLink: {
    textDecoration: "none",
    color: "yellow",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "#fff",
    "& input": {
      color: "#fff",
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 4.5),
    fontSize: "24px",
    disabled: {
      color: "#fff",
    },
  },
  confirmedMsg: {
    padding: "2px 0 23px 0",
    fontSize: "20px",
  },
  submitDisabled: {
    margin: theme.spacing(3, 0, 2),
    fontSize: "24px",
    color: "#FFF",
  },
  here: {
    margin: "0 !important",
    minWidth: "auto !important",
  },
  checkbox: {
    margin: theme.spacing(3, 0, 2),
  },
  agree: {
    margin: "29px 0 2px",
  },
  error: {
    color: "#fff",
    fontSize: "12px",
  },
  toolTip: {
    fontSize: "13px",
  },
  link: {
    wordBreak: "break-all",
  },
});

const BREAKPOINT_1 = 620;
const BREAKPOINT_2 = 450;

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username:
        this.props.authData && typeof this.props.authData.usr === "string"
          ? this.props.authData.usr
          : "",
      code: "",
      error: "",
      resend: false,
      loading: false,
      confirmed: false,
      size: [0, 0],
      helpdesk: false,
    };
    this.setSize = this.setSize.bind(this);
    this.resend = this.resend.bind(this);
    this.confirm = this.confirm.bind(this);
    this.return = this.return.bind(this);
    this.goToSignIn = this.goToSignIn.bind(this);
  }

  setSize() {
    this.setState((prev) => {
      return {
        ...prev,
        size: [window.innerWidth, window.innerHeight],
      };
    });
  }

  componentDidMount() {
    this.setSize();
    window.addEventListener("resize", this.setSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSize);
  }

  return() {
    this.props.onStateChange("signIn", this.state.username);
  }
  confirm() {
    this.setState({ loading: true });
    let usr =
      this.state.username !== ""
        ? this.state.username
        : this.props.authData
        ? this.props.authData.usr
        : "";
    Auth.confirmSignUp(usr, this.state.code)
      .then((data) => {
        this.setState({ loading: false, confirmed: true, error: "" });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
    this.setState({ loading: false });
  }

  resend() {
    Auth.resendSignUp(this.state.username)
      .then((res) => {
        this.setState({ resend: true, error: "" });
      })
      .catch((err) => this.setState({ error: err.message || err.log }));
  }

  goToSignIn() {
    this.props.onStateChange("signIn", {});
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  reset = () => {
    this.setState({
      username: "",
      code: "",
      error: "",
      resend: false,
      loading: false,
      confirmed: false,
    });
  };
  showHelpdesk = () => {
    this.setState((prev) => {
      return {
        ...prev,
        helpdesk: true,
        error: "",
      };
    });
  };

  hideHelpdesk = () => {
    this.setState((prev) => {
      return {
        ...prev,
        helpdesk: false,
        error: "",
      };
    });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.authState !== prevProps.authState &&
      this.props.authState === "confirmSignUp"
    ) {
      this.reset();
    }
  }

  makeResponsive(switcher) {
    switch (switcher) {
      case "layout-div":
        return this.state.size[0] > BREAKPOINT_1
          ? {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              minHeight: "100vh",
            }
          : this.state.size[0] > BREAKPOINT_2
          ? {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "130px 35px 130px 35px",
              minHeight: "100vh",
            }
          : {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "20px 0px 0px 0px",
              minHeight: "100vh",
            };
      case "paper":
        return this.state.size[0] > BREAKPOINT_1
          ? { padding: "40px 60px 40px 60px", marginTop: 20 }
          : this.state.size[0] > BREAKPOINT_2
          ? { padding: "80px 50px 80px 50px", marginTop: 20, width: "98%" }
          : {
              padding: "80px 20px 80px 20px",
              margin: "20px 5px 20px 5px",
              width: "98%",
            };
      default: {
        console.error(
          "Error: Wrong value provided to makeResponsive() function !"
        );
        return {};
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;
    return (
      <AppContext.Consumer>
        {(context) =>
          this.props.authState === "confirmSignUp" && (
            <Grid container component="main" className={classes.root}>
              <ErrorToast error={error} />
              <CssBaseline />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.bgStyle}
                style={this.makeResponsive("layout-div")}
              >
                {/*to do : wrap the App into MuiThemeProvider*/}
                <MuiThemeProvider
                  theme={
                    context.state.brandingColor
                      ? createMuiTheme(getTheme(context.state.brandingColor))
                      : createMuiTheme(getTheme("#FFFFFF"))
                  }
                >
                  <AppTitle big className={classes.apptitle} />
                  <Grid>
                    <Paper
                      className={classes.paper}
                      style={this.makeResponsive("paper")}
                    >
                      {!this.state.confirmed ? (
                        <div style={{ textAlign: "left", wordBreak: "normal" }}>
                          {!this.state.helpdesk ? (
                            <Typography variant="h1" className={classes.title}>
                              <Translate i18nKey="confirm" />
                            </Typography>
                          ) : (
                            <Typography variant="h1" className={classes.title}>
                              <Translate i18nKey="helpdesk_information" />
                            </Typography>
                          )}

                          <Typography
                            variant="body2"
                            style={{
                              display: "inline-block",
                              fontSize: "1rem",
                            }}
                          >
                            <Translate i18nKey="thank_you_for_registering._you_will_now_receive_an_email_with_a_verification_code._please_enter_the_verification_code_below_within_24_hours_and_you_will_then_be_able_to_submit_an_entry.please_check_your_junk_email_folder_if_you_haven’t_received_the_verification_code_before_contacting_the_helpdesk" />
                            <br />
                            <br />
                            {!this.state.helpdesk && (
                              <>
                                <Translate i18nKey="if_you_have_any_issues_please_contact_the" />
                                <BtnLink
                                  click={this.showHelpdesk}
                                  label={<Translate i18nKey="helpdesk" />}
                                />
                                <br />
                              </>
                            )}
                          </Typography>
                          {!this.state.helpdesk ? (
                            <form id="confirmForm" className={classes.form}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    className={classes.form}
                                    margin="normal"
                                    id="username"
                                    key="username"
                                    name="username"
                                    type="text"
                                    value={this.state.username}
                                    onChange={(e) => this.handleChange(e)}
                                    label={<Translate i18nKey="username" />}
                                    autoFocus
                                  />
                                </Grid>
                              </Grid>

                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    className={classes.form}
                                    label={<Translate i18nKey="code" />}
                                    margin="normal"
                                    id="code"
                                    key="code"
                                    name="code"
                                    type="text"
                                    value={this.state.code || ""}
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </Grid>
                              </Grid>

                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={this.confirm}
                                //disabled={!(values.checked && isValid)}
                              >
                                {this.state.loading ? (
                                  <CircularProgress
                                    style={{ color: "#FFFFFF" }}
                                  />
                                ) : (
                                  <Translate i18nKey="send" />
                                )}
                              </Button>
                            </form>
                          ) : (
                            <Typography
                              variant="body2"
                              style={{
                                display: "inline-block",
                                fontSize: "1rem",
                              }}
                            >
                              <ul className={classes.list}>
                                <li style={{ color: "white" }}>
                                  <Translate i18nKey="for_tt_and_classic_tt_enquiries_–" />
                                  <ul className={classes.list}>
                                    <li style={{ color: "white" }}>
                                      <Translate i18nKey="e-mail:_tthelpdesk@gov.im" />
                                    </li>
                                    <li style={{ color: "white" }}>
                                      <Translate i18nKey="tel:_+44_(0)_1624_686877_or_+44_(0)_1624_687087" />
                                    </li>
                                  </ul>
                                </li>
                                <br />
                                <li style={{ color: "white" }}>
                                  <Translate i18nKey="for_mgp_enquiries_–" />
                                  <ul className={classes.list}>
                                    <li style={{ color: "white" }}>
                                      <Translate i18nKey="e-mail:_office@mgp.co.im" />
                                    </li>
                                    <li style={{ color: "white" }}>
                                      <Translate i18nKey="tel:_+44_(0)_1624_644647_or_+44_(0)_1624_644649" />
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                              <br />
                              <Translate i18nKey="for_the_latest_regulations_and_information_please_visit_:" />
                              <br />
                              <ul>
                                <li style={{ color: "white" }}>
                                  <Typography>
                                    <a
                                      style={{
                                        color: "yellow",
                                        textDecoration: "none",
                                      }}
                                      href="https://www.iomttraces.com/competitor-information"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      TT
                                    </a>
                                  </Typography>
                                </li>
                                <li style={{ color: "white" }}>
                                  <Typography>
                                    <a
                                      style={{
                                        color: "yellow",
                                        textDecoration: "none",
                                      }}
                                      href="https://www.iomttraces.com/classic/competitor-information"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Classic TT
                                    </a>
                                  </Typography>
                                </li>
                                <li style={{ color: "white" }}>
                                  <Typography>
                                    <a
                                      style={{
                                        color: "yellow",
                                        textDecoration: "none",
                                      }}
                                      href="https://manxgrandprix.org/mgp-entry-regs/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      MGP
                                    </a>
                                  </Typography>
                                </li>
                              </ul>
                            </Typography>
                          )}

                          <Grid container style={{ width: "100%" }}>
                            <Grid item xs={6}>
                              <BtnLink
                                click={this.goToSignIn}
                                label={<Translate i18nKey="back_to_sign_in" />}
                              />
                            </Grid>
                            {this.state.helpdesk ? (
                              <Grid container style={{ width: "100%" }}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body2"
                                    style={{ display: "inline-block" }}
                                  >
                                    <Translate i18nKey="go_back_to_the_confirm_registration_form?" />
                                  </Typography>
                                  <BtnLink
                                    click={this.hideHelpdesk}
                                    label={<Translate i18nKey="click_here" />}
                                  />
                                </Grid>
                              </Grid>
                            ) : null}
                            {!this.state.helpdesk ? (
                              <Grid item xs={6}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="body2"
                                      style={{
                                        display: "inline-block",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <Translate i18nKey="lost_your_code" />?
                                    </Typography>
                                  </Grid>

                                  {this.state.resend === false ? (
                                    <Grid item xs={6}>
                                      <BtnLink
                                        click={this.resend}
                                        label={<Translate i18nKey="resend" />}
                                        disabled={!this.state.username}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={6}
                                      style={{ marginLeft: "-10px" }}
                                    >
                                      <Typography
                                        variant="body2"
                                        style={{ display: "inline-block" }}
                                      >
                                        <Translate i18nKey="check_your_inbox" />
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            ) : null}
                          </Grid>
                        </div>
                      ) : this.props.authData &&
                        this.props.authData.type === "manager" ? (
                        <div>
                          <Typography
                            variant="body2"
                            className={classes.confirmedMsg}
                            style={{
                              display: "inline-block",
                              fontSize: "1rem",
                            }}
                          >
                            <Translate i18nKey="thank_you_for_registering_for_the_isle_of_man_race_management_website_once_your_registration_is_approved_by_motorsport_team_you_will_be_notified_and_can_sign_in_into_the_system_click" />
                            <BtnLink
                              click={this.return}
                              clName={classes.here}
                              label={<Translate i18nKey="here" />}
                            />{" "}
                            <Translate i18nKey="to_return_to_the_login_page" />
                          </Typography>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            variant="body2"
                            className={classes.confirmedMsg}
                            style={{
                              display: "inline-block",
                              fontSize: "1rem",
                            }}
                          >
                            <Translate i18nKey="your_registration_for_the_Isle_of_Man_Race_Management_System_was_successful._please_click" />
                            <BtnLink
                              click={this.return}
                              label={<Translate i18nKey="here" />}
                            />
                            <Translate i18nKey="to_return_to_the_login_page_to_access_the_system." />
                          </Typography>
                        </div>
                      )}
                    </Paper>
                  </Grid>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          )
        }
      </AppContext.Consumer>
    );
  }
}
export default withStyles(useStyles)(Confirm);
