import React from "react";
import { TextField } from "@material-ui/core";
import Translate from "./Translate";

const Birthdate = props => {
  return (
    <TextField
      className={props.className || ""}
      margin={props.margin || "normal"}
      name={props.name || "date_of_birth"}
      fullWidth={props.fullWidth || true}
      required={props.required || false}
      type="date"
      value={props.value || ""}
      onChange={props.onChange}
      label={<Translate i18nKey={props.label} />}
      onBlur={props.handleBlur || (() => false)}
      InputLabelProps={{
        shrink: true
      }}
      disabled={props.disabled || false}
    />
  );
};

export default Birthdate;
