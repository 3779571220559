export default {
  s3: {
    region: process.env.REACT_APP_REGION || "S3_UPLOADS_BUCKET_REGION",
    bucket: "S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    name: "IOM",
    region: process.env.REACT_APP_REGION || "eu-west-1",
    endpoint:
      process.env.REACT_APP_API ||
      "https://jy0g1nli18.execute-api.eu-west-1.amazonaws.com/api"                     //"https://4idznm9s2j.execute-api.eu-west-1.amazonaws.com/api"
  },
  cognito: {
    frontOfficeUserPool: {
      region: process.env.REACT_APP_FO_REGION || "eu-west-1",
      userPoolId:
        process.env.REACT_APP_FO_USER_POOL_ID || "eu-west-1_aBVx9OsGp",          //"eu-west-1_7u95YCl72",
      userPoolWebClientId:
        process.env.REACT_APP_FO_CLIENT_ID || "3ge293mfmujtp1ef0i8ptk9lib"                 //"7hp45t6cl38rqie232a0kofvpc"
    },
    identityPoolId:
      process.env.REACT_APP_IDENTITY_POOL_ID ||
      "eu-west-1:9c47fc36-31b7-49f0-9a0f-5d8f8746153d"                          //"eu-west-1:5a092ac5-a216-4bdb-a6b0-4654017c25b6"
  },
  fontSize: {
    small: process.env.REACT_APP_FONT_SIZE_SM || "small",
    medium: process.env.REACT_APP_FONT_SIZE_MD || "medium",
    large: process.env.REACT_APP_FONT_SIZE_LG || "large",
    xlarge: process.env.REACT_APP_FONT_SIZE_XL || "x-large"
  }
};

export const REACT_APP_CLOUDFRONT_DOMAIN = process.env.REACT_APP_CLOUDFRONT_DOMAIN;

export const imageCompressionOptions = {
  maxSizeMB: // (default: Number.POSITIVE_INFINITY)
    4,
  maxWidthOrHeight: // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
    // Please check the Caveat part for details.
    undefined,
  onProgress: // optional, a function takes one progress argument (percentage from 0 to 100) 
    progress => {
      console.log('Compression Progress:', progress);
    },
  useWebWorker: // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    true,
  // following options are for advanced users
  maxIteration: // optional, max number of iteration to compress the image (default: 10) 
    10,
  // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
  // fileType: string,           // optional, fileType override
  // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
};