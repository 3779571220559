import React from "react";
import { Auth } from "aws-amplify";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import ErrorToast from "../common/ErrorToast";
import AppTitle from "../common/AppTitle";
import BtnLink from "../common/BtnLink";
import Translate from "../common/Translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppContext } from "../AppProvider";
import { getTheme } from "../theme";

const useStyles = (theme) => ({
  root: {
    display: "block",
    "& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
      boxShadow: "0 0 0 30px #242a37 inset !important",
      "-webkit-box-shadow": "0 0 0 30px #242a37 inset !important",
      "-webkit-text-fill-color": "#fff !important",
    },
    height: "100vh",
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.3)",
    },
    '& label, & label[data-shrink = "true"]': {
      color: "#7F8FA4",
      fontSize: "16px",
      fontFamily: "MuseoSans500",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    background: "#242A37",
    color: "#fff",
    maxWidth: "500px",
  },
  title: {
    marginBottom: theme.spacing(2.75),
  },
  subTitle: {
    paddingBottom: theme.spacing(3),
  },
  forgotPass: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bgStyle: {
    background: "#181C28 !important",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "#fff",
    "& input": {
      color: "#fff",
    },
  },
  submit: {
    margin: theme.spacing(4, 0, 4.5),
    fontSize: "24px",
    disabled: {
      color: "#fff",
    },
  },
  confirmedMsg: {
    padding: "2px 0 23px 0",
    fontSize: "20px",
  },
  submitDisabled: {
    margin: theme.spacing(3, 0, 2),
    fontSize: "24px",
    color: "#FFF",
  },
  here: {
    margin: "0 !important",
    minWidth: "auto !important",
  },
  checkbox: {
    margin: theme.spacing(3, 0, 2),
  },
  agree: {
    margin: "29px 0 2px",
  },
  error: {
    color: "#fff",
    fontSize: "12px",
  },
  toolTip: {
    fontSize: "13px",
  },
  link: {
    wordBreak: "break-all",
  },
  helpDesk: {
    textAlign: "left",
    marginBottom: 50,
  },
  externalLink: {
    color: 'yellow',
    wordBreak: "break-word",
    textDecoration: 'none',
    '&:visited': {
      color: 'yellow'
    }
  },
  leftSpace: {
    paddingLeft: 20,
  },
});

const BREAKPOINT_1 = 520;
const BREAKPOINT_2 = 450;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      code: "",
      new_password: "",
      resend: false,
      correctUser: false,
      error: "",
      confirmed: false,
      size: [0, 0],
    };
    this.setSize = this.setSize.bind(this);
  }

  setSize() {
    this.setState((prev) => {
      return {
        ...prev,
        size: [window.innerWidth, window.innerHeight],
      };
    });
  }

  hideCode = () => {
    this.setState({ correctUser: true, error: "" });
  };

  componentDidMount() {
    this.setSize();
    window.addEventListener("resize", this.setSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSize);
  }

  return() {
    this.props.onStateChange("signIn", this.state.username);
  }
  confirm = () => {
    if (this.state.resend || this.state.correctUser) {
      Auth.forgotPasswordSubmit(
        this.state.username,
        this.state.code.trim(),
        this.state.new_password
      )
        .then((data) => {
          this.setState({ confirmed: true, error: "" });
          // this.props.onStateChange("signIn");
        })
        .catch((err) => {
          this.setState({ error: err.message || err.log });
          this.setState({ loading: false });
        });
    } else {
      Auth.forgotPassword(this.state.username)
        .then((data) => {
          this.setState({ resend: true, correctUser: true, error: "" });
        })
        .catch((err) => {
          this.setState({ error: err.message || err.log });
          this.setState({ loading: false });
        });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.confirm();
    }
  };

  goToSignIn = () => {
    this.props.onStateChange("signIn", {});
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  reset = () => {
    this.setState({
      username: "",
      code: "",
      new_password: "",
      confirmed: false,
      resend: false,
      correctUser: false,
      error: "",
    });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.authState !== prevProps.authState &&
      this.props.authState === "forgotPassword"
    ) {
      this.reset();
    }
  }

  makeResponsive(switcher) {
    switch (switcher) {
      case "layout-div":
        return this.state.size[0] > BREAKPOINT_1
          ? {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              minHeight: "100vh",
            }
          : this.state.size[0] > BREAKPOINT_2
          ? {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "130px 35px 130px 35px",
              minHeight: "100vh",
            }
          : {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "20px 0px 0px 0px",
              minHeight: "100vh",
            };
      case "paper":
        return this.state.size[0] > BREAKPOINT_1
          ? { padding: "80px 80px 80px 80px", marginTop: 20 }
          : this.state.size[0] > BREAKPOINT_2
          ? { padding: "80px 50px 80px 50px", marginTop: 20, width: "98%" }
          : {
              padding: "80px 20px 80px 20px",
              margin: "20px 5px 20px 5px",
              width: "98%",
            };
      default: {
        console.error(
          "Error: Wrong value provided to makeResponsive() function !"
        );
        return {};
      }
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <AppContext.Consumer>
        {(context) =>
          this.props.authState === "forgotPassword" && (
            <Grid container component="main" className={classes.root}>
              <ErrorToast error={this.state.error} />
              <CssBaseline />
              <Grid
                item
                component={Paper}
                xs={12}
                sm={12}
                md={12}
                className={classes.bgStyle}
                style={this.makeResponsive("layout-div")}
              >
                {/*to do : wrap the App into MuiThemeProvider*/}
                <MuiThemeProvider
                  theme={
                    context.state.brandingColor
                      ? createMuiTheme(getTheme(context.state.brandingColor))
                      : createMuiTheme(getTheme("#FFFFFF"))
                  }
                >
                  <AppTitle big className={classes.apptitle} />
                  <Grid>
                    <Paper
                      className={classes.paper}
                      style={this.makeResponsive("paper")}
                    >
                      <div>
                        <Typography
                          variant="h1"
                          className={classes.title}
                          style={{ fontSize: "2.5rem" }}
                        >
                          <Translate i18nKey="reset_your_password" />
                        </Typography>
                        {this.state.confirmed === false ? (
                          <div>
                            <Typography
                              variant="body2"
                              style={{ display: "inline-block" }}
                            >
                              <Translate i18nKey="please_input_your_username_below._an_email_will_be_sent_to_the_email_address_linked_to_the_account_with_a_code_to_reset_your_password._this_code_is_valid_for_24_hours" />{" "}
                            </Typography>
                            <form id="confirmForm" className={classes.form}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    className={classes.form}
                                    margin="normal"
                                    id="username"
                                    key="username"
                                    name="username"
                                    type="text"
                                    value={this.state.username || ""}
                                    onChange={(e) => this.handleChange(e)}
                                    label={<Translate i18nKey="username" />}
                                    onKeyPress={this.handleKeyPress}
                                    autoFocus
                                  />
                                </Grid>
                              </Grid>
                              {this.state.correctUser && (
                                <div>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-end"
                                  >
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        label={<Translate i18nKey="code" />}
                                        margin="normal"
                                        id="code"
                                        key="code"
                                        name="code"
                                        type="text"
                                        value={this.state.code || ""}
                                        onChange={(e) => this.handleChange(e)}
                                        onKeyPress={this.handleKeyPress}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-end"
                                  >
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        label={
                                          <Translate i18nKey="new_password" />
                                        }
                                        margin="normal"
                                        id="new_password"
                                        key="new_password"
                                        name="new_password"
                                        type="password"
                                        value={this.state.new_password || ""}
                                        onChange={(e) => this.handleChange(e)}
                                        onKeyPress={this.handleKeyPress}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={this.confirm}
                                //disabled={!(values.checked && isValid)}
                              >
                                {this.state.loading ? (
                                  <CircularProgress
                                    style={{ color: "#FFFFFF" }}
                                  />
                                ) : (
                                  <Translate i18nKey="send" />
                                )}
                              </Button>
                            </form>
                          </div>
                        ) : (
                          <div style={{ textAlign: "left" }}>
                            <div>
                              <div>
                                <Typography>
                                  {/* <Translate i18nKey="text_block_1" /> */}
                                  {
                                    "If you have any issues please contact the Helpdesk:"
                                  }
                                </Typography>
                              </div>
                              <div style={{ marginTop: 10 }}>
                                <div>
                                  <div style={{ color: "white" }}>
                                    <Typography>
                                      {"For TT and Classic TT enquiries –"}
                                    </Typography>
                                    {/* <Typography>{"Bruce Baker or Rob Temple"}</Typography> */}
                                    <div>
                                      <div>
                                        <Typography
                                          className={classes.leftSpace}
                                        >
                                          {"• E-mail: tthelpdesk@gov.im"}
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          className={classes.leftSpace}
                                        >
                                          {
                                            "• Tel: +44 (0)1624 686877 or +44 (0)1624 687087"
                                          }
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{ color: "white", marginTop: 20 }}
                                  >
                                    <Typography>
                                      {"For MGP enquiries –"}
                                    </Typography>
                                    <Typography>
                                      {"Heather Cleator or Ann Kinvig"}
                                    </Typography>
                                    <div>
                                      <div>
                                        <Typography
                                          className={classes.leftSpace}
                                        >
                                          {
                                            "• E-mail: heather@mgp.co.im or ann@mgp.co.im"
                                          }
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          className={classes.leftSpace}
                                        >
                                          {
                                            "• Tel: +44 (0)1624 644647 or +44 (0) 1624 644649"
                                          }
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                  <Typography>
                                    {
                                      "For the latest Regulations and information please visit:"
                                    }
                                  </Typography>
                                </div>
                                <div style={{ marginTop: 5 }}>
                                  <div>
                                    <div style={{ marginTop: 10 }}>
                                      <Typography>
                                        <a
                                          className={classes.externalLink}
                                          href="https://www.iomttraces.com/competitor-information"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          TT
                                        </a>
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ color: "white", marginTop: 10 }}
                                    >
                                      <Typography>
                                        <a
                                          className={classes.externalLink}
                                          href="https://www.iomttraces.com/classic/competitor-information"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Classic TT
                                        </a>
                                      </Typography>
                                    </div>
                                    <div
                                      style={{ color: "white", marginTop: 10 }}
                                    >
                                      <Typography>
                                        <a
                                          className={classes.externalLink}
                                          href="https://manxgrandprix.org/mgp-entry-regs/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          MGP
                                        </a>
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <Grid
                          container
                          style={{ width: "100%", marginTop: 20 }}
                        >
                          <Grid item xs>
                            <BtnLink
                              click={this.goToSignIn}
                              label={<Translate i18nKey="back_to_sign_in" />}
                            />
                          </Grid>
                          {this.state.correctUser && (
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="body2"
                                    style={{ display: "inline-block" }}
                                  >
                                    <Translate i18nKey="lost_your_code" />?
                                  </Typography>
                                </Grid>

                                {this.state.resend === false ? (
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginLeft: "-20px" }}
                                  >
                                    <BtnLink
                                      click={this.confirm}
                                      label={<Translate i18nKey="resend" />}
                                      disabled={!this.state.username}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginLeft: "-10px" }}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{ display: "inline-block" }}
                                    >
                                      <Translate i18nKey="check_your_inbox" />
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          )}
                          {!this.state.correctUser ? (
                            <Grid
                              container
                              style={{ width: "100%", marginLeft: "5px" }}
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  style={{ display: "inline-block" }}
                                >
                                  <Translate i18nKey="already_have_a_code?" />
                                </Typography>
                                <BtnLink
                                  click={this.hideCode}
                                  label={<Translate i18nKey="click_here" />}
                                />
                              </Grid>
                            </Grid>
                          ) : null}
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          )
        }
      </AppContext.Consumer>
    );
  }
}
export default withStyles(useStyles)(ResetPassword);
