import { withRouter } from "react-router";
import { lazy } from "react";

export const Home = withRouter(
  lazy(() => import(/* webpackChunkName: "home" */ "../home/Home"))
);

export const Profile = withRouter(
  lazy(() => import(/* webpackChunkName: "profile" */ "../user/Profile"))
);

export const PersonalData = withRouter(
  lazy(() => import(/* webpackChunkName: "personal data" */ "../profile/personalData/PersonalData"))
);

export const NextOfKin = withRouter(
  lazy(() => import(/* webpackChunkName: "next of kin" */ "../profile/nextOfKin/NextOfKin"))
);

export const Dependants = withRouter(
  lazy(() => import(/* webpackChunkName: "dependants" */ "../profile/dependants/Dependants"))
);

export const EmergencyContacts = withRouter(
  lazy(() => import(/* webpackChunkName: "emergency contacts" */ "../profile/emergencyContacts/EmergencyContacts"))
);

export const BillingData = withRouter(
  lazy(() => import(/* webpackChunkName: "billing data" */ "../profile/billingData/Billing"))
);

export const BioData = withRouter(
  lazy(() => import(/* webpackChunkName: "bio data" */ "../profile/bioData/Bio"))
);

export const MedicalQuestions = withRouter(
  lazy(() => import(/* webpackChunkName: "medical questions" */ "../profile/medicalData/MedicalQuestions"))
);

export const MedicalInformations = withRouter(
  lazy(() => import(/* webpackChunkName: "medical informations" */ "../profile/medicalData/MedicalInformations"))
);

export const PitCrew = withRouter(
  lazy(() => import(/* webpackChunkName: "pit crew" */ "../profile/pitCrew/PitCrew"))
);

export const Confirm = withRouter(
  lazy(() => import(/* webpackChunkName: "Confirm" */ "../auth/Confirm"))
);

export const Services = withRouter(
  lazy(() => import(/* webpackChunkName: "services" */ "../user/Services"))
);

export const Registration = withRouter(
  lazy(() => import(/* webpackChunkName: "registration" */ "../registrations/registrationDashboard/Registration"))
);

export const Redirection = withRouter(
  lazy(() => import(/* webpackChunkName: "registration" */ "../registrations/registrationDashboard/RedirectionPage"))
);

export const NewRegistrationEntry = withRouter(
  lazy(() => import(/* webpackChunkName: "newRegistrationEntry" */ "../registrations/newRegistration/NewRegistrationEntry"))
);

export const RegistrationDetails = withRouter(
  lazy(() => import(/* webpackChunkName: "registrationEntryDetails" */ "../registrations/registrationEntryDetails/EntryDetails"))
);

export const NewMachine = withRouter(
  lazy(() => import(/* webpackChunkName: "createVehicle" */ "../machines/newMachine/NewMachine"))
);

export const MachineDetails = withRouter(
  lazy(() => import(/* webpackChunkName: "machineDetails" */ "../machines/machineDetails/MachineDetails"))
);

export const NewLicence = withRouter(
  lazy(() => import(/* webpackChunkName: "create licence" */ "../licences/newLicence/NewLicence"))
);

export const LicenceDetails = withRouter(
  lazy(() => import(/* webpackChunkName: "licence detials" */ "../licences/licenceDetails/LicenceDetails"))
);

// export const Licences = withRouter(
//   lazy(() => import(/* webpackChunkName: "licences" */ "../licences/Licences"))
// );

// export const Machines = withRouter(
//   lazy(() => import(/* webpackChunkName: "vehicles" */ "../machines/Machines"))
// );

export const Documentation = withRouter(
  lazy(() => import(/* webpackChunkName: "template" */ "../documentation/Documentation"))
);

export const Paddock = withRouter(
  lazy(() => import(/* webpackChunkName: "paddock" */ "../paddock/Paddock"))
);

export const HelpDesk = withRouter(
  lazy(() => import(/* webpackChunkName: "helpDeskInfo" */ "../helpDeskInfo/HelpDeskInformations"))
);

export const Notifications = withRouter(
  lazy(() => import(/* webpackChunkName: "notifications" */ "../notifications/Notifications"))
);

export const Results = withRouter(
  lazy(() => import(/* webpackChunkName: "results" */ "../results/Results"))
);

export const ContactForm = withRouter(
  lazy(() => import(/* webpackChunkName: "ContactForm" */ "../contactForm/ContactForm"))
);

export const InvalidRouting = withRouter(
  lazy(() => import(/* webpackChunkName: "InvalidRouting" */ "../common/InvalidRouting"))
);