import React from "react";
import { Auth } from "aws-amplify";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Formik } from "formik";
import * as Yup from "yup";
import AppTitle from "../common/AppTitle";
import BtnLink from "../common/BtnLink";
import Translate from "../common/Translate";
import { Link, IconButton, FormControlLabel } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Birthdate from "../common/Birthdate";
import { getTheme } from "../theme";
import { AppContext } from "../AppProvider";
import ErrorToast from "../common/ErrorToast";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Calendar from "@material-ui/icons/Event";
import yellow from "@material-ui/core/colors/yellow";
const DEFAULT = "#ffffff";

const useStyles = (themeSecond) => ({
  root: {
    "& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
      boxShadow: "0 0 0 30px #242a37 inset !important",
      "-webkit-box-shadow": "0 0 0 30px #242a37 inset !important",
      "-webkit-text-fill-color": "#fff !important",
    },
    height: "100vh",
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.3)",
    },
    '& label, & label[data-shrink = "true"]': {
      color: "#7F8FA4",
      fontSize: "16px",
      fontFamily: "MuseoSans500",
    },
  },
  paper: {
    margin: themeSecond.spacing(0.75, 4),
    display: "flex",
    flexDirection: "column",
    background: "#242A37",
    color: "#fff",
    padding: themeSecond.spacing(5.5, 8, 2.5),
    overflow: "auto",
  },
  title: {
    marginBottom: themeSecond.spacing(2.75),
  },
  subTitle: {
    paddingBottom: themeSecond.spacing(3),
  },
  forgotPass: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bgStyle: {
    background: "#181C28 !important",
  },
  form: {
    width: "100%",
    marginTop: themeSecond.spacing(1),
    color: "#fff",
    "& input": {
      color: "#fff",
    },
  },
  submit: {
    margin: themeSecond.spacing(0, 0, 4.5),
    fontSize: "24px",
    disabled: {
      color: "#fff",
    },
  },
  submitDisabled: {
    margin: themeSecond.spacing(3, 0, 2),
    fontSize: "24px",
    color: "#FFF",
  },
  checkbox: {
    margin: themeSecond.spacing(3, 0, 2),
  },
  agree: {
    margin: "29px 0 2px",
  },
  error: {
    color: "red",
    fontSize: "13px",
  },
  toolTip: {
    fontSize: 13,
  },

  iconButton: {
    borderRadius: 100,
    padding: 2,
    color: "#fff",
  },
  externalLink: {
    textDecoration: "none",
    color: "yellow",
  },
});

const materialTheme = createMuiTheme({
  palette: {
    primary: yellow,
  },
  overrides: {
    MuiTextField: {
      root: {
        "& ::before": {
          borderBottomColor: "rgba(255,255,255,0.3)",
        },
        "&:hover": {
          borderBottomColor: ".3px solid " + DEFAULT,
        },
        "& input": {
          color: DEFAULT,
          borderBottomColor: "black",
          fontFamily: "MuseoSans300",
          "&:disabled": {
            opacity: 0.5,
            backgroundColor: "#ffffff00",
            width: "100%",
            paddingLeft: 0,
            borderBottom: "none",
            "&:hover": {
              borderBottomColor: "transparent",
              cursor: "not-allowed",
            },
            "&:disabled:before": {
              borderBottom: "none",
            },
          },
        },
        "&:focus": {
          borderBottomColor: "transparent",
        },
      },
    },
    MuiInput: {
      root: {
        paddingLeft: 3,
      },
      underline: {
        "&:before": {
          borderBottomColor: "rgba(255,255,255,0.3)",
        },
        "&:after": {
          borderBottom: "2px solid gold",
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottomColor: DEFAULT,
        },
        "&:hover": {
          borderBottomColor: DEFAULT,
        },
      },
    },
    MuiInputBase: {
      input: {
        color: DEFAULT,
        borderBottomColoe: ".3px solid " + DEFAULT + "ef",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#0000007a",
      },
    },
    MuiIconButton: {
      label: {
        color: "rgba(250, 246, 246, 0.41)",
      },
    },
    MuiTypography: {
      colorInherit: {
        color: "black",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 30,
        width: "100%",
        color: "black",
      },
      textPrimary: {
        color: "black",
        fontWeight: "bold",
      },
    },
    MuiSelect: {
      root: {
        color: DEFAULT,
        "&$disabled": {
          borderBottom: "none",
          backgroundColor: "#fff0",
          "&:hover": {
            cursor: "not-allowed",
          },
        },
      },
      icon: {
        color: DEFAULT,
      },
    },
    MuiFormLabel: {
      root: {
        "&$disabled": {
          color: DEFAULT + "aa",
        },
        "&:focused": {
          color: "white",
        },
      },
    },
  },
});

const BREAKPOINT_1 = 1700;
const BREAKPOINT_2 = 1300;
const BREAKPOINT_3 = 800;
const BREAKPOINT_4 = 450;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "competitor",
      checked: false,
      error: "",
      userName: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      licence: "",
      size: [0, 0],
      support_text_1: false,
      support_text_2: false,
      support_text_3: false,
      support_text_4: false,
      support_text_5: false,
      support_text_6: false,
      support_text_7: false,
      support_text_8: false,
      helpdesk: false,
    };
    this.setSize = this.setSize.bind(this);
    this.goToSignIn = this.goToSignIn.bind(this);
    this.goToSignup = this.goToSignup.bind(this);
    this.goToConfirmation = this.goToConfirmation.bind(this);
  }

  setSize() {
    this.setState((prev) => {
      return {
        ...prev,
        size: [window.innerWidth, window.innerHeight],
      };
    });
  }

  componentDidMount() {
    this.setSize();
    window.addEventListener("resize", this.setSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSize);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.authState !== prevProps.authState &&
      this.props.authState === "signUp"
    ) {
      this.reset();
    }
  }
  reset = () => {
    this.setState((prev) => {
      return {
        ...prev,
        userType: "competitor",
        checked: false,
        error: "",
        userName: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        licence: "",
      };
    });
  };
  goToSignIn() {
    this.props.onStateChange("signIn", {});
  }
  goToSignup(text) {
    this.props.onStateChange("signUp", { attr: text });
  }

  goToConfirmation() {
    this.props.onStateChange("confirmSignUp", this.state.us);
  }

  validateForm = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.signUp();
    }
  };
  showHelpdesk = () => {
    this.setState((prev) => {
      return {
        ...prev,
        helpdesk: true,
        error: "",
      };
    });
  };

  hideHelpdesk = () => {
    this.setState((prev) => {
      return {
        ...prev,
        helpdesk: false,
        error: "",
      };
    });
  };

  makeResponsive(switcher) {
    switch (switcher) {
      case "form_container":
        return this.state.size[0] > BREAKPOINT_1
          ? { padding: "60px 400px 60px 400px" }
          : this.state.size[0] > BREAKPOINT_2
          ? { padding: "60px 200px 60px 200px" }
          : this.state.size[0] > BREAKPOINT_3
          ? { padding: "60px 50px 60px 50px" }
          : this.state.size[0] > BREAKPOINT_3
          ? { padding: "60px 10px 60px 10px" }
          : { padding: "20px 0px 0px 0px" };
      case "paper":
        return this.state.size[0] > BREAKPOINT_2
          ? { padding: "80px 80px 80px 80px" }
          : this.state.size[0] > BREAKPOINT_3
          ? { padding: "80px 50px 80px 50px" }
          : { padding: "40px 20px 40px 20px", margin: "20px 5px 20px 5px" };
      default: {
        console.error(
          "Error: Wrong value provided to makeResponsive() function !"
        );
        return {};
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;

    const signupSchema = Yup.object().shape({
      type: Yup.string(),
      username: Yup.string()
        .matches(
          /^[A-Za-z0-9]*\.{0,1}[A-Za-z0-9]+$/,
          "Usernames may contain letters (a-z), numbers (0-9) and only one full stop (.)"
        )
        .min(6, "Minimum 6 characters")
        .required("This field is mandatory to fill in"),
      email: Yup.string()
        .email("Invalid email")
        .required("This field is mandatory to fill in"),
      firstName: Yup.string()
        .matches(
          /^([a-zA-Z]+?)([\s'][a-zA-Z]+)*?$/,
          "Firstname contains only alphabetic characters and apostrophes"
        )
        .required("This field is mandatory to fill in"),
      lastName: Yup.string()
        .matches(
          /^([a-zA-Z]+?)([\s'][a-zA-Z]+)*?$/,
          "Lastname contains only alphabetic characters and apostrophes"
        )
        .required("This field is mandatory to fill in"),
      password: Yup.string()
        .required("This field is mandatory to fill in")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
          "password must contain at least a number, uppercase, lowercase and a special character (^ $ * . [ ] { } ( ) ? ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)"
        )
        .min(9)
        .notOneOf(
          [Yup.ref("username"), null],
          "Password must be different from user name"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password doesn't match")
        .required("This field is mandatory to fill in"),
      // licence:
      //   this.state.userType === "competitor"
      //     ? Yup.string().required("Required")
      //     : Yup.string(),
      birthdate:
        this.state.userType === "competitor"
          ? Yup.date().required("Required")
          : Yup.date(),
      phone_number:
        this.state.userType === "competitor"
          ? Yup.string()
              .matches(/^\+[\d][\d\s]{2,29}$/, "Invalid phone number format")
              .required("This field is mandatory to fill in")
          : Yup.string(),
      team_name:
        this.state.userType === "manager"
          ? Yup.string().required("Required")
          : Yup.string(),
    });

    return (
      <AppContext.Consumer>
        {(context) =>
          this.props.authState === "signUp" && (
            <Grid container component="main" className={classes.root}>
              <ErrorToast error={error} />
              <CssBaseline />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                component={Paper}
                // elevation={6}
                square
                className={classes.bgStyle}
                style={this.makeResponsive("form_container")}
              >
                {/*to do : wrap the App into MuiThemeProvider*/}
                <MuiThemeProvider
                  theme={
                    context.state.brandingColor
                      ? createMuiTheme(getTheme(context.state.brandingColor))
                      : createMuiTheme(getTheme("#FFFFFF"))
                  }
                >
                  <AppTitle big />
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Paper
                      className={classes.paper}
                      style={this.makeResponsive("paper")}
                    >
                      {!this.state.helpdesk ? (
                        <Typography variant="h1" className={classes.title}>
                          <Translate i18nKey="create_account" />
                        </Typography>
                      ) : (
                        <Typography variant="h1" className={classes.title}>
                          <Translate i18nKey="helpdesk_information" />
                        </Typography>
                      )}
                      {!this.state.helpdesk && (
                        <Typography
                          variant="body2"
                          style={{
                            display: "inline-block",
                            fontSize: "1rem",
                          }}
                        >
                          <Translate i18nKey="if_you_have_any_problems_when_creating_an_account_please_contact_the" />
                          <BtnLink
                            click={this.showHelpdesk}
                            label={<Translate i18nKey="helpdesk" />}
                          />
                        </Typography>
                      )}

                      {!this.state.helpdesk ? (
                        <Formik
                          enableReinitialize={true}
                          initialValues={{ type: this.state.userType }}
                          validateOnMount={false}
                          validationSchema={signupSchema}
                        >
                          {(formikProps) => {
                            const {
                              values,
                              touched,
                              errors,
                              handleChange,
                              isValid,
                              handleBlur,
                              setFieldValue,
                            } = formikProps;

                            return (
                              <form
                                id="signUpForm"
                                noValidate
                                className={classes.form}
                              >
                                <Grid container alignItems="flex-end">
                                  <Grid item xs={12}>
                                    <Tooltip
                                      title={
                                        <span className={classes.toolTip}>
                                          <Translate i18nKey="username_can_contain_letters,_numbers_and_fullstops" />
                                        </span>
                                      }
                                      arrow={false}
                                      disableHoverListener={false}
                                      disableFocusListener={true}
                                    >
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        margin="normal"
                                        id="username"
                                        key="username"
                                        name="username"
                                        type="text"
                                        value={values.username || ""}
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        label={<Translate i18nKey="username" />}
                                      />
                                    </Tooltip>
                                    {errors.username && touched.username && (
                                      <div className={classes.error}>
                                        {errors.username}
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container alignItems="flex-end">
                                  <Grid item xs={12}>
                                    <Tooltip
                                      title={
                                        <span className={classes.toolTip}>
                                          <Translate i18nKey="use_a_personal_email_and_not_a_workplace_email_address" />
                                        </span>
                                      }
                                      arrow={false}
                                      disableHoverListener={false}
                                      disableFocusListener={true}
                                    >
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        label={<Translate i18nKey="Email" />}
                                        autoComplete="email"
                                        margin="normal"
                                        id="email"
                                        key="email"
                                        name="email"
                                        type="email"
                                        value={values.email || ""}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={handleBlur}
                                      />
                                    </Tooltip>
                                    {errors.email && touched.email && (
                                      <div className={classes.error}>
                                        {errors.email}
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container alignItems="flex-end">
                                  <Grid item xs={12}>
                                    <Tooltip
                                      title={
                                        <span className={classes.toolTip}>
                                          <Translate i18nKey="firstname_must_be_entered_exactly_as_in_race_licence,_please_note_that_apostrophes_are_permitted_but_must_not_be_used_at_the_beginning_or_end_of_the_word." />
                                        </span>
                                      }
                                      arrow={false}
                                      disableHoverListener={false}
                                      disableFocusListener={true}
                                    >
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        label={
                                          <Translate i18nKey="first_name" />
                                        }
                                        margin="normal"
                                        id="firstName"
                                        key="firstName"
                                        name="firstName"
                                        type="text"
                                        value={values.firstName || ""}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={handleBlur}
                                      />
                                    </Tooltip>
                                    {errors.firstName && touched.firstName && (
                                      <div className={classes.error}>
                                        {errors.firstName}
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container alignItems="flex-end">
                                  <Grid item xs={12}>
                                    <Tooltip
                                      title={
                                        <span className={classes.toolTip}>
                                          <Translate i18nKey="lastname_must_be_entered_exactly_as_in_race_licence,_please_note_that_apostrophes_are_permitted_but_must_not_be_used_at_the_beginning_or_end_of_the_word." />
                                        </span>
                                      }
                                      arrow={false}
                                      disableHoverListener={false}
                                      disableFocusListener={true}
                                    >
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        label={
                                          <Translate i18nKey="last_name" />
                                        }
                                        margin="normal"
                                        id="lastName"
                                        key="lastName"
                                        name="lastName"
                                        type="text"
                                        value={values.lastName || ""}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={handleBlur}
                                      />
                                    </Tooltip>
                                    {errors.lastName && touched.lastName && (
                                      <div className={classes.error}>
                                        {errors.lastName}
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                                {this.state.userType === "competitor" ? (
                                  <Grid container alignItems="flex-end">
                                    <Grid item xs={12}>
                                      <Tooltip
                                        title={
                                          <span className={classes.toolTip}>
                                            <Translate i18nKey="please_use_the_following_format_+XXXXXXXXXXX." />
                                          </span>
                                        }
                                        arrow={false}
                                        disableHoverListener={false}
                                        disableFocusListener={true}
                                      >
                                        <TextField
                                          fullWidth
                                          className={classes.form}
                                          margin="normal"
                                          label={
                                            <Translate i18nKey="phone_number" />
                                          }
                                          placeholder="+XXXXXXXXXXX"
                                          type="text"
                                          name="phone_number"
                                          value={values.phone_number || ""}
                                          onChange={(e) => handleChange(e)}
                                          onBlur={handleBlur}
                                        />
                                      </Tooltip>
                                      {errors.phone_number &&
                                        touched.phone_number && (
                                          <div className={classes.error}>
                                            {errors.phone_number}
                                          </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <ThemeProvider theme={materialTheme}>
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            name="birthdate"
                                            style={{ width: "100%" }}
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="dd/MM/yyyy"
                                            value={values.birthdate || null}
                                            onChange={(value) => {
                                              setFieldValue(
                                                "birthdate",
                                                value,
                                                false
                                              );
                                            }}
                                            InputProps={{ readOnly: true }}
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                            label="Date of Birth"
                                            helperText=""
                                            error={false}
                                            keyboardIcon={
                                              <Calendar
                                                style={{
                                                  marginTop: "-10px",
                                                  color: "#fff",
                                                  fontSize: "21px",
                                                }}
                                              />
                                            }
                                          />
                                        </MuiPickersUtilsProvider>
                                      </ThemeProvider>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-end"
                                  >
                                    <Grid item xs={11}>
                                      <Birthdate
                                        name="birthdate"
                                        value={values.birthdate || ""}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={handleBlur}
                                        label="birthdate"
                                      />
                                      {errors.birthdate &&
                                        touched.birthdate && (
                                          <div className={classes.error}>
                                            {errors.birthdate}
                                          </div>
                                        )}
                                    </Grid>{" "}
                                    <Grid item xs={11}>
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        name="licence"
                                        margin="normal"
                                        label={
                                          <Translate i18nKey="Mountain_Course_Licence_Number" />
                                        }
                                        type="text"
                                        value={values.licence || ""}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={handleBlur}
                                      />
                                      {errors.licence && touched.licence && (
                                        <div className={classes.error}>
                                          {errors.licence}
                                        </div>
                                      )}
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Tooltip
                                        title={
                                          <span className={classes.toolTip}>
                                            <Translate i18nKey="If you do not have this please email motorsport@gov.im for support" />
                                          </span>
                                        }
                                        arrow={true}
                                        open={this.state.support_text_5}
                                      >
                                        <IconButton
                                          className={classes.iconButton}
                                          onClick={() => {
                                            this.setState((prev) => {
                                              return {
                                                ...prev,
                                                support_text_5: !prev.support_text_5,
                                              };
                                            });
                                          }}
                                        >
                                          <InfoOutlinedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid container alignItems="flex-end">
                                  <Grid item xs={12}>
                                    <Tooltip
                                      title={
                                        <span className={classes.toolTip}>
                                          <Translate i18nKey="please_note_that_your_password_must_contain_at_least_one_number,_upper_case_letter,_lower_case_letter_and_a_special_character" />
                                        </span>
                                      }
                                      arrow={false}
                                      disableHoverListener={false}
                                      disableFocusListener={true}
                                    >
                                      <TextField
                                        fullWidth
                                        className={classes.form}
                                        margin="normal"
                                        label={<Translate i18nKey="password" />}
                                        type="password"
                                        id="password"
                                        key="password"
                                        name="password"
                                        value={values.password || ""}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={handleBlur}
                                      />
                                    </Tooltip>
                                    {errors.password && touched.password && (
                                      <div className={classes.error}>
                                        {errors.password}
                                      </div>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container alignItems="flex-end">
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      className={classes.form}
                                      margin="normal"
                                      label={
                                        <Translate i18nKey="re_enter_password" />
                                      }
                                      type="password"
                                      name="confirmPassword"
                                      value={values.confirmPassword || ""}
                                      onChange={(e) => handleChange(e)}
                                      onBlur={handleBlur}
                                    />
                                    {errors.confirmPassword &&
                                      touched.confirmPassword && (
                                        <div className={classes.error}>
                                          {errors.confirmPassword}
                                        </div>
                                      )}
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  spacing={1}
                                  className={classes.agree}
                                >
                                  <Grid
                                    item
                                    xs={
                                      this.state.size[0] > BREAKPOINT_4
                                        ? false
                                        : 11
                                    }
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="checked"
                                          value={values.checked || false}
                                          checked={values.checked || false}
                                          color="primary"
                                          onChange={(e) => handleChange(e)}
                                          onBlur={handleBlur}
                                        />
                                      }
                                      label={
                                        <Typography variant="body2">
                                          <Translate i18nKey="i_agree_with" />
                                          <Link
                                            style={{
                                              color: "yellow",
                                              textDecoration: "none",
                                            }}
                                            href="https://www.iomttraces.com/legal/entry-terms-and-conditions"
                                            target="_blank"
                                          >
                                            <Translate i18nKey="terms_and_conditions" />
                                          </Link>
                                        </Typography>
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={
                                      this.state.size[0] > BREAKPOINT_4
                                        ? false
                                        : 1
                                    }
                                  >
                                    <Tooltip
                                      title={
                                        <span className={classes.toolTip}>
                                          <Translate i18nKey="Tick here to confirm acceptance of website Terms and conditions" />
                                        </span>
                                      }
                                      arrow={true}
                                      open={this.state.support_text_7}
                                    >
                                      <IconButton
                                        className={classes.iconButton}
                                        onClick={() => {
                                          this.setState((prev) => {
                                            return {
                                              ...prev,
                                              support_text_7: !prev.support_text_7,
                                            };
                                          });
                                        }}
                                      >
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>

                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  onClick={() => this.signUp(values)}
                                  disabled={!(values.checked && isValid)}
                                >
                                  {this.state.loading ? (
                                    <CircularProgress
                                      style={{
                                        color: "#FFF",
                                      }}
                                    />
                                  ) : (
                                    <Translate i18nKey="sign_up" />
                                  )}
                                </Button>
                              </form>
                            );
                          }}
                        </Formik>
                      ) : (
                        <Typography
                          variant="body2"
                          style={{
                            display: "inline-block",
                            fontSize: "1rem",
                          }}
                        >
                          <ul className={classes.list}>
                            <li style={{ color: "white" }}>
                              <Translate i18nKey="for_tt_and_classic_tt_enquiries_–" />
                              <ul className={classes.list}>
                                <li style={{ color: "white" }}>
                                  <Translate i18nKey="e-mail:_tthelpdesk@gov.im" />
                                </li>
                                <li style={{ color: "white" }}>
                                  <Translate i18nKey="tel:_+44_(0)_1624_686877_or_+44_(0)_1624_687087" />
                                </li>
                              </ul>
                            </li>
                            <br />
                            <li style={{ color: "white" }}>
                              <Translate i18nKey="for_mgp_enquiries_–" />
                              <ul className={classes.list}>
                                <li style={{ color: "white" }}>
                                  <Translate i18nKey="e-mail:_office@mgp.co.im" />
                                </li>
                                <li style={{ color: "white" }}>
                                  <Translate i18nKey="tel:_+44_(0)_1624_644647_or_+44_(0)_1624_644649" />
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <br />
                          <Translate i18nKey="for_the_latest_regulations_and_information_please_visit_:" />
                          <br />
                          <ul>
                            <li style={{ color: "white" }}>
                              <Typography>
                                <a
                                  style={{
                                    color: "yellow",
                                    textDecoration: "none",
                                  }}
                                  href="https://www.iomttraces.com/competitor-information"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  TT
                                </a>
                              </Typography>
                            </li>
                            <li style={{ color: "white" }}>
                              <Typography>
                                <a
                                  style={{
                                    color: "yellow",
                                    textDecoration: "none",
                                  }}
                                  href="https://www.iomttraces.com/classic/competitor-information"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Classic TT
                                </a>
                              </Typography>
                            </li>
                            <li style={{ color: "white" }}>
                              <Typography>
                                <a
                                  style={{
                                    color: "yellow",
                                    textDecoration: "none",
                                  }}
                                  href="https://manxgrandprix.org/mgp-entry-regs/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  MGP
                                </a>
                              </Typography>
                            </li>
                          </ul>
                        </Typography>
                      )}

                      <Grid container style={{ width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            style={{
                              display: "inline-block",
                              fontSize: "1rem",
                            }}
                          >
                            <Translate i18nKey="have_an_account" />?
                          </Typography>
                          <BtnLink
                            click={this.goToSignIn}
                            label={<Translate i18nKey="sign_in" />}
                          />
                        </Grid>

                        {/* <Grid item style={{ float: "right", padding: "0px" }}>
                          <BtnLink
                            click={() =>
                              this.setState({
                                userType: "competitor",
                                error: "",
                              })
                            }
                            label={<Translate i18nKey="competitor" />}
                          />
                          {"  or "}
                        </Grid>

                        <Grid item style={{ float: "right", padding: "0px" }}>
                          <BtnLink
                            click={() =>
                              this.setState({ userType: "manager", error: "" })
                            }
                            label={<Translate i18nKey="team_manager" />}
                          />
                          {" signup"}
                        </Grid> */}
                      </Grid>
                      <Grid container style={{ width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            style={{
                              display: "inline-block",
                              fontSize: "1rem",
                            }}
                          >
                            <Translate i18nKey="got_a_confirmation_code" />?
                          </Typography>
                          <BtnLink
                            click={this.goToConfirmation}
                            label={<Translate i18nKey="confirm_sign_up" />}
                          />
                        </Grid>
                      </Grid>
                      {this.state.helpdesk ? (
                        <Grid container style={{ width: "100%" }}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              style={{
                                display: "inline-block",
                                fontSize: "1rem",
                              }}
                            >
                              <Translate i18nKey="go_back_to_the_register_form?" />
                            </Typography>
                            <BtnLink
                              click={this.hideHelpdesk}
                              label={<Translate i18nKey="click_here" />}
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </Paper>
                  </Grid>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          )
        }
      </AppContext.Consumer>
    );
  }

  signUp(values) {
    const {
      password,
      confirmPassword,
      username,
      email,
      firstName,
      lastName,
      type,
      phone_number,
      birthdate,
      // licence,

      // team_name,
    } = values;
    const dob =
      birthdate.getFullYear() +
      "-" +
      ("0" + (birthdate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + birthdate.getDate()).slice(-2);

    if (password !== confirmPassword) {
      this.setState({
        error: "Passwords didn't match. Please try again.",
      });
      return;
    }

    this.setState({ loading: true });
    const data =
      type === "competitor"
        ? {
            username: username,
            password: password,
            attributes: {
              email: email,
              given_name: firstName,
              family_name: lastName,
              phone_number: phone_number.replace(/\s/g, ""),
              "custom:user_category": type,
              birthdate: dob,
              // "custom:license": licence,
            },
          }
        : {
            username: username,
            password: password,
            attributes: {
              email: email,
              given_name: firstName,
              family_name: lastName,
              phone_number: phone_number.replace(/\s/g, ""),
              // "custom:team_name": team_name,
              "custom:user_category": "team_manager",
            },
          };
    Auth.signUp(data)
      .then((data) => {
        this.props.onStateChange("confirmSignUp", {
          usr: data.user.username,
          type: type,
        });
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("error", err);
        this.setState({ error: err || err.log });
        this.setState({ loading: false });
      });
  }
}

export default withStyles(useStyles)(SignUp);
