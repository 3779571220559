import React, { forwardRef, useLayoutEffect, useEffect, useState } from "react";
import clsx from "clsx";
// import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  MenuItem,
  Divider,
  Menu,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
// import MainMenu from "./MainMenu";
import AppTitle from "./AppTitle";
import { AppContext } from "../AppProvider";
import config from "../config";
import Translate from "./Translate";
// import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Footer from "./Footer";
import HomeIcon from "@material-ui/icons/Home";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { getUserDetails, getValueToken } from "../common/cognito-services";
import { Auth, API } from "aws-amplify";
import Badge from "@material-ui/core/Badge";
const drawerWidth = 290;
// const appBarHeight = 65;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appTitle: {
    fontSize: ".95rem",
  },
  appBar: {
    // height: appBarHeight,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  confirmedMsg: {
    padding: "2px 0 23px 0",
    fontSize: "11px",
    fontWeight: "bold",
    color: theme.palette.background.primary.main,
    marginTop: "-10px",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: theme.palette.primary.main,
    padding: 0,
    borderRadius: "none",
    width: "100%",
    height: "100%",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiPaper-root": {
      width: drawerWidth,
      overflow: "hidden",
    },
  },
  footer: {
    background: "red",
    position: "absolute",
    bottom: 0,
    height: "200px",
    flex: "auto",
  },

  drawerHeader: {
    // Set drawer header background this is only applied to logo background, keep transparent and update the parent background in drawerPaper
    backgroundColor: "transparent",
    color: "#fff",
    display: "grid",
    gridTemplateColumns: "1fr 2.5rem",
    padding: 0,
    paddingLeft: 60,
    textTransform: "capitalize",
  },
  content: {
    marginTop: theme.spacing(4),
    width: "100%",
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  langSwitcher: {
    padding: "9px 0 9px 11px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  langMenu: {
    "&:hover": {
      backgroundColor: "unset",
      cursor: "initial",
    },
  },
  email: {
    fontWeight: "bold",
  },
  fontMenu: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  toolbar: {
    // height: appBarHeight,
    // display: "grid",
    gridTemplateColumns: "4rem 1fr 10rem",
    // Set appBar color here (this where the burger icon exists and the profile icon) (don't touch, update in theme.js)
    background: theme.palette.background.primary.main + "00",
  },
  container: {
    marginTop: theme.spacing(4),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  mainMenu: {
    marginTop: theme.spacing(2),
    // Set list <li> background this is only applied to the menu items, keep transparent and update the parent background in drawerPaper
    backgroundColor: "transparent",
    paddingLeft: theme.spacing(8),
  },
  iomLogo: {
    "& img": {
      height: '100%',
      width: 85,
      display: "block",
      margin: "auto",
    },
  },
  toolbarButton: {
    // Set appbar right icon color
    background: theme.palette.primary.main,
    color: theme.palette.background.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "99",
    },
  },
  menuIfClosed: {
    height: "100%",
    background: theme.palette.primary.main,
    "& *": {
      height: "100%",

      color: theme.palette.background.primary.main,
    },
  },
  closeMainMenu: {
    color: theme.palette.primary.main,
  },
  toolbarRegular: {
    minHeight: 0,
  },
}));

const NavMenuItem = forwardRef((props, ref) => {
  return (
    <MenuItem
      component={NavLink}
      to={{ pathname: props.route, search: window.location.search }}
      onClick={props.onClick}
      classes={props.classes}
    >
      {props.children}
    </MenuItem>
  );
});

const MOBILE_BREAKPOINT = 500;

export default function Layout(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open /*, setOpen*/] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openUserMenu = Boolean(anchorEl);
  const [customFontSize, setCustomFontSize] = React.useState(null);
  const [size, setSize] = useState([0, 0]);
  const [newNotifs, setNewNotifs] = useState();
  const [userAccount, setUserAccount] = useState();
  const [credentials, setCredentials] = useState();

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    Auth.currentUserCredentials().then((res) => {
      setCredentials({
        region: config.cognito.frontOfficeUserPool.region,
        credentials: Auth.essentialCredentials(res),
      });
    });
  }, []);
  useEffect(() => {
    if (credentials) {
      Auth.currentUserPoolUser()
        .then((res) => getUserDetails(res.username, credentials))
        .then((res) => setUserAccount(res));
    }
  }, [credentials]);

  useEffect(() => {
    if (userAccount && userAccount.UserAttributes) {
      API.get(
        "IOM",
        `/accounts/${getValueToken(
          userAccount,
          "sub"
        )}/my_notifications?status=NEW`
      ).then((res) => {
        if (res && res.data) {
          setNewNotifs(res.data.length);
          localStorage.setItem("newNotifs", JSON.stringify(res.data.length));
        }
      });
    }
  }, [userAccount, props.location]);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeFontSize = (event, sizeLabel) => {
    const size = config.fontSize[sizeLabel];
    setCustomFontSize(size);
  };

  const makeResponsive = (switcher) => {
    switch (switcher) {
      case "left-buttons":
        return size[0] > MOBILE_BREAKPOINT ? null : { padding: 7 };
      case "right-buttons":
        return size[0] > MOBILE_BREAKPOINT
          ? { marginLeft: 5, padding: 17 }
          : { marginLeft: 5, padding: 12 };
      case "right-buttons-new-notifs":
        return size[0] > MOBILE_BREAKPOINT
          ? { marginLeft: 5, padding: 14 }
          : { marginLeft: 5, padding: 12 };
      default: {
        console.error("Error: Unknown value provided to makeResponsive");
        return null;
      }
    }
  };

  return (
    <AppContext.Consumer>
      {(context) => (
        <div className={classes.root}>
          <CssBaseline />
          {customFontSize && (
            <style jsx="true">{`
              *
                :not(#app-title-open):not(#app-title-close):not(#burger):not(#burger-closed):not(.mainMenu):not(h1):not(.icon-ntt) {
                font-size: ${customFontSize};
              }
            `}</style>
          )}
          <AppBar className={classes.appBar}>
            <Toolbar
              classes={{ regular: classes.toolbarRegular }}
              className={classes.toolbar}
              disableGutters
            >
              <div /*className={classes.menuIfClosed}*/>
                <Tooltip title="Home">
                  <NavLink to="/home">
                    <IconButton
                      // aria-label="open drawer"
                      onClick={() => localStorage.setItem('last_selected', '')}
                      // className={classes.menuButton}
                      className={classes.toolbarButton}
                      style={makeResponsive("left-buttons")}
                    >
                      <HomeIcon
                        fontSize="large" /*style={{ fontSize: '40px' }}*/
                      />
                    </IconButton>
                  </NavLink>
                </Tooltip>
              </div>
              <div className={classes.iomLogo}>
                <img src={props.logo} alt="Event logo" style={{ width: size[0] > 450 ? 100 : 75 }} />
              </div>
              <div style={{ margin: "auto auto auto 0px" }}>
                <AppTitle
                  id="app-title-open"
                  className={classes.appTitle}
                  title={size[0] >= 600 ? 'IOM - MOUNTAIN COURSE' : size[0] > 450 ? 'IOM - MC' : ''}
                />
              </div>
              <div style={{ width: "max-content" }}>
                <Tooltip title="Previous page">
                  <IconButton
                    className={classes.toolbarButton}
                    style={makeResponsive("right-buttons")}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <ArrowBackIcon size="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="My notifications">
                  <NavLink to="/notifications">
                    <IconButton
                      className={classes.toolbarButton}
                      style={makeResponsive("right-buttons")}
                      aria-label="user-notifications"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                    >
                      <Badge badgeContent={newNotifs} color="error">
                        <NotificationsIcon size="small" />
                      </Badge>
                    </IconButton>
                  </NavLink>
                </Tooltip>
                <Tooltip title="My account">
                  <IconButton
                    className={classes.toolbarButton}
                    style={makeResponsive("right-buttons")}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                  >
                    <AccountCircleIcon size="small" />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openUserMenu}
                  onClose={handleClose}
                  className={classes.menu}
                >
                  <NavMenuItem
                    route="/profile"
                    onClick={handleClose}
                    className={classes.langMenu}
                  >
                    <div>
                      <div className={classes.email}>
                        {Auth.user.attributes.given_name}{" "}
                        {Auth.user.attributes.family_name}
                      </div>
                      <div>{Auth.user.attributes.email}</div>
                    </div>
                  </NavMenuItem>
                  <Divider />
                  {/* <NavMenuItem route="/profile" onClick={handleClose}>
                    <Translate i18nKey="my_profile" />
                  </NavMenuItem>

                  <NavMenuItem route="/services" onClick={handleClose}>
                    <Translate i18nKey="my_services" />
                  </NavMenuItem> */}
                  {/* TODO enable this for i18n support
                   <MenuItem
                    aria-label="change language"
                    aria-controls="menu-lang"
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.langMenu}
                  >
                    <Translate i18nKey="language" />
                  </MenuItem>
                  <div className={classes.langSwitcher}>
                    <BtnLink
                      click={event => {
                        context.changeLanguage("en");
                        handleClose();
                      }}
                      label="English"
                      color={context.state.language !== "en" && "inherit"}
                    />
                    /
                    <BtnLink
                      click={event => {
                        context.changeLanguage("fr");
                        handleClose();
                      }}
                      label="Français"
                      color={context.state.language !== "fr" && "inherit"}
                    />
                  </div> */}
                  <MenuItem
                    aria-label="change size"
                    aria-controls="menu-lang"
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.langMenu}
                  >
                    <Translate i18nKey="change_text_size" />
                  </MenuItem>
                  <MenuItem className={classes.fontMenu}>
                    <ButtonGroup
                      color="secondary"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        onClick={(event) => changeFontSize(event, "small")}
                      >
                        SM
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "medium")}
                      >
                        MD
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "large")}
                      >
                        LG
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "xlarge")}
                      >
                        XL
                      </Button>
                    </ButtonGroup>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => Auth.signOut().then(() => history.push("/"))}>
                    <Translate i18nKey="sign_out" />
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          {/* <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Toolbar
              variant="dense"
              disableGutters
              className={classes.drawerHeader}
            >
              <div className={classes.iom}>
                <img src={getActiveEventTypeLogo()} alt="Event logo" />
              </div> */}

          {/* <AppTitle
                id="app-title-close"
                className={classes.appTitle}
                style={{ color: menuButtonColor }}
              /> */}
          {/* <IconButton
                onClick={handleDrawerClose}
                className={classes.menuButton}
              >
                <ArrowLeftIcon className={classes.closeMainMenu} />
              </IconButton>
            </Toolbar>
            <MainMenu className={classes.mainMenu} />
          </Drawer> */}

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            {props.children}
            <Footer size={size} />
          </main>
        </div>
      )}
    </AppContext.Consumer>
  );
}
