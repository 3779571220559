import React from "react";
import Translate from "./Translate";

const ErrorToast = (props) => {
  if (!props.error) {
    return null;
  }
  if (props.error.code) {
    switch (props.error.code) {
      case "InvalidParameterException":
        if (props.error["message"].includes("confirmationCode")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_confirmation_code" />
              </span>
            </div>
          );
        } else if (props.error["message"].includes("regular expression")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_passed_username" />
              </span>
            </div>
          );
        } else if (props.error["message"].includes("lambda")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="empty_password" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["message"]}</span>
            </div>
          );
        }

      case "UserLambdaValidationException":
        if (props.error["message"].includes("Invalid username")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_username" />
              </span>
            </div>
          );
        } else if (props.error["message"].includes("INVALID_LICENCE")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_licence" />
              </span>
            </div>
          );
        } else if (
          props.error["message"].includes("This username is already in use")
        ) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="the_same_username_exist" />
              </span>
            </div>
          );
        } else if (
          props.error["message"].includes("This username and email are already in use")
        ) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="the_same_email_username_exist" />
              </span>
            </div>
          );
        } else if (
          props.error["message"].includes("This email adress is already in use")
        ) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="the_same_email_exist" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["message"]}</span>
            </div>
          );
        }
      case "ValidationError":
        if (props.error["message"].includes("password")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="invalid_password" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["message"]}</span>
            </div>
          );
        }

      default:
        return (
          <div className="toast">
            <span>{props.error.message}</span>
          </div>
        );
    }
  } else if (props.error.name) {
    switch (props.error.name) {
      case "AuthError":
        if (props.error["log"].includes("username should either be a string")) {
          return (
            <div className="toast">
              <span>
                <Translate i18nKey="empty_username" />
              </span>
            </div>
          );
        } else {
          return (
            <div className="toast">
              <span>{props.error["log"]}</span>
            </div>
          );
        }
      default:
        return (
          <div className="toast">
            <span>{props.error.log}</span>
          </div>
        );
    }
  } else if (
    props.error.includes("Username/client id combination not found.")
  ) {
    return (
      <div className="toast">
        <span>
          <Translate i18nKey="user does not exist" />
        </span>
      </div>
    );
  } else if (
    props.error.includes(
      "1 validation error detected: Value at 'username' failed to satisfy constraint"
    )
  ) {
    return (
      <div className="toast">
        <span>
          <Translate i18nKey="enter_valid_username" />
        </span>
      </div>
    );
  } else if (
    props.error.includes(
      "2 validation errors detected: Value at 'password' failed to satisfy constraint"
    ) ||
    props.error.includes(
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6"
    )
  ) {
    return (
      <div className="toast">
        <span>
          <Translate i18nKey="password_must_contain_at_least_a_number,_uppercase,_lowercase_and_a_special_character_and_be_9_characters_long_at_least" />
        </span>
      </div>
    );
  } else if (props.error.includes("Cannot reset password")) {
    return (
      <div className="toast">
        <span>
          <Translate i18nKey="cannot_reset_password_for_the_user_as_there_is_no_registered_verified_email" />
        </span>
      </div>
    )
  }
  else {
    return (
      <div className="toast">
        <span>
          {props.error
            ? props.error
            : "An error has occured. Please check your information"}
        </span>
      </div>
    );
  }
};
export default ErrorToast;
