import { Auth } from "aws-amplify";

export function getUrlParameter(location, name) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function currentUser() {
  return {
    id: Auth.user.attributes.sub,
    defaultTeamId: localStorage.getItem("tid"),
    firstName: Auth.user.attributes["given_name"],
    lastName: Auth.user.attributes["family_name"],
    email: Auth.user.attributes.email,
    username: Auth.user.username,
    fullName:
      Auth.user.attributes["given_name"] +
      " " +
      Auth.user.attributes["family_name"]
  };
}
