import React, { useState, useContext, useEffect } from "react";
import App from "./App";
import { AppContext } from "./AppProvider";

import ttLogo from "./assets/images/iomtt.png";
import cttLogo from "./assets/images/iomctt.png";
import mgpLogo from "./assets/images/iommgp.png";
import { API } from "aws-amplify";

function getQueryVariable(location, variable) {
  let query = location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

const userPath = getQueryVariable(window.location, "userPath");

const BrandingProvider = () => {
  let context = useContext(AppContext);
  const [PRIMARY, setPRIMARY] = useState('#FFF');
  const [logo, setLogo] = useState();
  const TT = "#fae100";
  const CTT = "#ef3340";
  const MGP = "#00b258";

  const url = `/brands${userPath ? "?userPath=" + userPath : "/"}`;

  useEffect(() => {
    API.get("IOM", url).then(res => {
      switch (res.data && res.data.attributes.brand_type.toLowerCase()) {
        case "tt": {
          setLogo(ttLogo);
          setPRIMARY(TT);
          if (context.state.brandingColor !== TT) context.setBrandingColor(TT);
          break;
        }
        case "ctt": {
          setLogo(cttLogo);
          setPRIMARY(CTT);
          if (context.state.brandingColor !== CTT) context.setBrandingColor(CTT);
          break;
        }
        case "mgp": {
          setLogo(mgpLogo);
          setPRIMARY(MGP);
          if (context.state.brandingColor !== MGP) context.setBrandingColor(MGP);
          break;
        }
        default:
          setLogo(ttLogo);
          setPRIMARY(TT);
          if (context.state.brandingColor !== TT) context.setBrandingColor(TT);
          break;
      }
    });
  }, [url, context]);
  
  return (PRIMARY && logo) ? <App PRIMARY={PRIMARY} logo={logo} /> : null;
};

export default BrandingProvider;
