import AWS from "aws-sdk";
import config from "../config";
const frontOfficeUserPoolId = config.cognito.frontOfficeUserPool.userPoolId;

export const getUserDetails = (username, auth) => {
    const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
    const params = {
      Username: username,
      UserPoolId: frontOfficeUserPoolId,
    };
    return new Promise((resolve) => {
      identityProvider.adminGetUser(params, function (error, data) {
        if (error) {
          console.error(JSON.stringify(error));
        } else {
          resolve(data);
        }
      });
    });
  };

  export const getValueToken = (cognitoUser, attributeName) => {
    const attribute = cognitoUser["UserAttributes"].find(
      (a) => a["Name"] === attributeName
    );
    return attribute ? attribute.Value : "";
  };