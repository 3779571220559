import React, { Component } from "react";
import BrandingProvider from './BrandingProvider'
import { setCurrentLanguage, getCurrentLanguage } from "./common/language";

// Create new context
export const AppContext = React.createContext();

// Then create a Provider Component
export class AppProvider extends Component {
  state = {
    language: getCurrentLanguage(),
    brandingColor: undefined,
  };

  changeLanguage = lang => {
    this.setState(
      { language: lang }, //updater
      () => {
        setCurrentLanguage(this.state.language);
      } // callback
    );
  };

  setBrandingColor = color => {
    this.setState(
      { brandingColor: color },
    )
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          changeLanguage: this.changeLanguage,
          setBrandingColor: this.setBrandingColor
        }}
      >
        <BrandingProvider />   {/* BrandingProvider contains the whole APP, App.jsx */}
      </AppContext.Provider>
    );
  }
}
