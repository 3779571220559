import React from "react";
import { makeStyles, Button, useTheme } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    margin: "0 5px",
    fontSize: theme.typography.button.fontSize,
    "&:hover": {
      textDecoration: "none"
    }
  }
}));

export default function BtnLink(props) {
  const theme = useTheme()
  const classes = useStyles();
  return (
    <Button
      className={[classes.root, props.clName].join(" ")}
      target={props.target}
      onClick={props.click}
      style={{
        color: props.disabled ? "#a39e9e" : (props.color ? props.color : theme.palette.primary.main)
      }}
      disabled = {props.disabled || false}
    >
      {props.label}
    </Button>
  );
}
