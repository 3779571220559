export const getTheme = PRIMARY => {
  const BREAK = "#fff";
  const SECONDARY = "#222222";
  const DEFAULT = "#ffffff";
  const PRIMARY_GRADIENT = `linear-gradient(to bottom left, ${PRIMARY}, ${PRIMARY}99) !important`;
  const BACKGROUND_PRIMARY = "#000000";
  const FONT_COLOR_PRIMARY = "#000000";
  const BACKGROUND_TERTIARY = "#FFFFFF16";
  const BACKGROUND_GRADIENT = `linear-gradient(to bottom left, ${BACKGROUND_TERTIARY}, rgba(255,255,255,.055))`;
  const FONT_COLOR_SECONDARY = "#ffffff";
  const BACKGROUND_SECONDARY = "#ffffff";
  const BACKGROUND_TERTIARY_GRADIENT = `linear-gradient(to top left, ${BACKGROUND_TERTIARY}, rgba(255,255,255,.055))`;
  return {
    overrides: {
      MuiListItemIcon: {
        root: {
          color: DEFAULT
        }
      },
      MuiAppBar: {
        root: {},
        colorPrimary: {
          backgroundColor: BACKGROUND_PRIMARY + "cc"
        }
      },
      MuiChip: {
        root: {
          marginRight: 4,
          marginBottom: 4,
          transition: "none"
        },
        colorPrimary: {
          background: "transparent",
          "& .MuiAvatar-root": {
            backgroundColor: SECONDARY + "EE"
          }
        },
        clickableColorPrimary: {
          color: FONT_COLOR_SECONDARY + "dd",
          background: DEFAULT + "11",
          "& .MuiTypography-root": {
            color: FONT_COLOR_SECONDARY + "dd"
          },
          "&:hover": {
            color: SECONDARY,
            transition: "none",
            background: `linear-gradient(to bottom right, ${PRIMARY}, ${PRIMARY}99) !important`,
            "& .MuiAvatar-root": {
              backgroundColor: SECONDARY + "EE"
            }
          }
        }
      },
      MuiDrawer: {
        root: {
          "& .MuiDrawer-paperAnchorDockedLeft": {
            borderRight: "none"
          },
          "& .MuiPaper-root": {
            backgroundColor: BACKGROUND_PRIMARY + "BB",
            width: 290
          },
          "& .MuiTypography-root": {
            color: FONT_COLOR_SECONDARY
          }
        }
      },
      MuiExpansionPanel: {
        root: {
          "& .MuiTypography-root": {
            color: FONT_COLOR_SECONDARY
          },
          "& .MuiIconButton-root": {
            color: FONT_COLOR_SECONDARY + "66"
          },
          overflow: "hidden",
          color: FONT_COLOR_SECONDARY,
          background: BACKGROUND_PRIMARY,
          height: "auto",
          boxSizing: "border-box",
          borderRadius: 6,
          margin: "4px 0 0 0",
          padding: 0
        }
      },
      MuiExpansionPanelSummary: {
        root: {
          color: FONT_COLOR_SECONDARY,
          background: BACKGROUND_TERTIARY_GRADIENT
        }
      },
      MuiExpansionPanelDetails: {
        root: {
          color: FONT_COLOR_SECONDARY,
          background: BACKGROUND_TERTIARY + "22"
        }
      },
      MuiCard: {
        root: {
          background: BACKGROUND_GRADIENT,
          height: "100%",
          minHeight: 200,
          maxWidth: 345,
          "& *": {
            color: BACKGROUND_SECONDARY
          },
          boxShadow: "0px 1px 1px 0 rgba(0,0,0,0.12)"
        }
      },
      MuiCardActionArea: {
        root: {
          borderRadius: 4,
          height: "100%"
        }
      },
      MuiPaper: {
        root: {
          boxShadow: "0px 1px 1px 0 rgba(0,0,0,0.12)"
        }
      },
      MuiCardMedia: {
        root: {
          marginTop: 0,
          height: 140,
          backgroundSize: "auto 60%"
        }
      },
      MuiCardContent: {
        root: {
          paddingTop: 10
        }
      },
      MuiTypography: {
        root: {
          fontFamily: "MuseoSans300",
          fontSize: "1rem"
        },
        caption: {
          fontFamily: "MuseoSans300",
          color: FONT_COLOR_SECONDARY
        },
        body1: {
          fontFamily: "MuseoSans300",
          color: FONT_COLOR_SECONDARY
        },
        body2: {
          fontFamily: "MuseoSans300",
          color: FONT_COLOR_SECONDARY
        },
        h1: {
          display: "inline-block",
          color: PRIMARY,
          fontSize: "3rem",
          fontFamily: "AgencyBold, sans-serif",
          textTransform: "uppercase",
          marginTop: 0,
          marginBottom: 8,
          paddingBottom: 0,
          padding: 0
        },
        h3: {
          color: PRIMARY,
          fontSize: "1.15rem",
          fontFamily: "AgencyBold, sans-serif",
          margin: "1%"
        },
        h2: {
          color: PRIMARY,
          fontSize: "1.25rem",
          fontFamily: "AgencyBold, sans-serif",
          textTransform: "uppercase"
        },
        h5: {
          color: FONT_COLOR_SECONDARY,
          fontSize: "1rem",
          fontFamily: "AgencyBold, sans-serif"
        },
        h6: {
          color: FONT_COLOR_SECONDARY,
          fontSize: ".9rem",
          fontFamily: "MuseoSans300"
        }
      },
      MuiTable: {
        root: {
          display: "table",
          tableLayout: "fixed",
          backgroundColor: BACKGROUND_TERTIARY
        }
      },
      MuiTableHead: {
        root: {
          background: BACKGROUND_TERTIARY_GRADIENT,
          textTransform: "uppercase"
        }
      },
      MuiTableSortLabel: {
        root: {
          color: FONT_COLOR_SECONDARY + "aa",
          textTransform: "uppercase",
          fontWeight: 800,
          "&.MuiTableSortLabel-active": {
            color: DEFAULT
          }
        }
      },
      MuiTableRow: {
        root: {
          minHeight: 74,
          cursor: "pointer",
          "&$selected": {
            borderLeft: "3px solid " + PRIMARY
          }
        }
      },
      MuiTableCell: {
        root: {
          color: FONT_COLOR_SECONDARY,
          fontSize: "0.9rem",
          borderBottom: "1px solid " + BACKGROUND_PRIMARY
        },
        body: {
          color: FONT_COLOR_SECONDARY
        }
      },
      MuiSwitch: {
        root: {},
        track: {
          backgroundColor: DEFAULT + "99",
        },
        thumb: {}
      },
      MuiInput: {
        root: {
          paddingLeft: 3
        },
        underline: {
          "&:before": {
            borderBottomColor: "#f3f4f7"
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: DEFAULT
          },
          "&:hover": {
            borderBottomColor: DEFAULT
          }
        }
      },
      MuiInputLabel: {
        root: {
          color: DEFAULT,
          paddingLeft: 3
        }
      },
      MuiInputBase: {
        input: {
          color: DEFAULT,
          borderBottomColoe: ".3px solid " + DEFAULT + "ef",
          "&$disabled": {
            opacity: "0.5"
          }
        }
      },
      MuiSelect: {
        root: {
          color: DEFAULT,
          "&$disabled": {
            borderBottom: "1px solid #f3f4f7",
            backgroundColor: "#fff0",
            borderBottomStyle: "dotted",
            "&:hover": {
              cursor: "not-allowed"
            }
          }
        },
        icon: {
          color: DEFAULT
        }
      },
      MuiFormLabel: {
        root: {
          "&$disabled": {
            color: DEFAULT + "aa"
          }
        }
      },
      MuiTextField: {
        root: {
          "& ::before": {
            borderBottom: ".3px solid " + DEFAULT + "ef"
          },
          "&:hover": {
            borderBottomColor: ".3px solid " + DEFAULT
          },
          "& input": {
            color: DEFAULT,

            fontFamily: "MuseoSans300",
            "&:disabled": {
              opacity: 0.5,
              backgroundColor: "#FFF1",
              paddingLeft: 10,
              borderBottom: "none",
              "&:hover": {
                borderBottomColor: "transparent",
                cursor: "not-allowed"
              },
              "&:disabled:before": {
                borderBottom: "none"
              }
            }
          }
        }
      },
      MuiButton: {
        root: {
          backgroundColor: DEFAULT + "33",
          borderRadius: 2.3,
          fontFamily: "AgencyBold",
          color: FONT_COLOR_SECONDARY + "99",
          "&:hover": {
            backgroundColor: DEFAULT + "22",
            textDecoration: "none"
          },
          "&:disabled": {
            color: FONT_COLOR_SECONDARY + "56",
            backgroundColor: DEFAULT + "11"
          }
        },
        contained: {
          color: FONT_COLOR_PRIMARY + "bb",
          backgroundColor: DEFAULT,
          "&$disabled": {
            color: FONT_COLOR_SECONDARY + "44",
            backgroundColor: DEFAULT + "20"
          }
        }
      },

      MuiLink: {
        root: {
          fontFamily: "MuseoSans300"
        }
      },

      MuiListItemText: {
        primary: {
          fontSize: "14px"
        }
      },
      MuiDivider: {
        root: {
          marginTop: 14,
          marginBottom: 14
        }
      },
      MuiStepper: {
        root: {
          // borderBottom: ".3px solid #334",
          backgroundColor: BACKGROUND_PRIMARY,
          color: PRIMARY
        }
      },
      MuiStepLabel: {
        root: {},
        label: {
          "&.MuiStepLabel-completed": {
            color: FONT_COLOR_SECONDARY + "99"
          },
          color: FONT_COLOR_SECONDARY + "66",
          "&.MuiStepLabel-active": {
            color: PRIMARY
          }
        }
      },
      MuiStepIcon: {
        root: {
          fontSize: "1.6rem",
          color: "#FFF3",
          // the active icon
          "&.MuiStepIcon-active": {
            color: PRIMARY,
            "& text": {
              // this is the text color
              fill: FONT_COLOR_PRIMARY
            }
          },
          "&.MuiStepIcon-completed": {
            color: PRIMARY + "99"
          }
        }
      },
      MuiStepConnector: {
        line: {
          borderWidth: 0.4,
          borderColor: "#FFF2"
        }
      },
      MuiIconButton: {
        root: {
          height: "100%",
          display: "inline-block",
          color: "#2c3d61",
          borderRadius: "none"
        }
      }
    },

    palette: {
      default: {
        main: DEFAULT
      },
      primary: {
        // main: "#f54b64"
        main: PRIMARY,
        gradient: PRIMARY_GRADIENT
      },
      secondary: {
        // main: "#2c3d61"
        main: SECONDARY
      },
      background: {
        gradient: BACKGROUND_GRADIENT,
        default: "#f3f4f7",
        paper: "#fff",
        primary: {
          main: BACKGROUND_PRIMARY
        },
        secondary: {
          main: BACKGROUND_SECONDARY
        },
        tertiary: {
          main: BACKGROUND_TERTIARY,
          gradient: BACKGROUND_TERTIARY_GRADIENT
        }
      },
      break: {
        main: BREAK
      },
      text: {
        primary: FONT_COLOR_PRIMARY,
        secondary: FONT_COLOR_SECONDARY
      },
      divider: "#323b4e"
    }
  };
};
// eslint-disable-next-line
// const PREMIUM_BUTTON = {
//   background: theme.palette.primary.gradient,
//   "&:hover": {
//     background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.primary.main}99) !important`,
//     backgroundColor: "#000 !important"
//   }
// };
