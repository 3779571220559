import React from "react";
import ReactDOM from "react-dom";
import config from "./config";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import { AppProvider } from "./AppProvider";

let userPool = "frontOfficeUserPool";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    ...config.cognito[userPool],
    identityPoolId: config.cognito.identityPoolId
  },
  Storage: {
    region: config.s3.region,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.identity_pool_id
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.name,
        endpoint: config.apiGateway.endpoint,
        region: config.apiGateway.region
      }
    ]
  }
});
ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
