import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  withAuthenticator,
  ConfirmSignIn as AmplifyConfirmSignIn,
  VerifyContact as AmplifyVerifyContact,
  //ConfirmSignUp as AmplifyConfirmSignUp,
  //ForgotPassword as AmplifyForgotPassword,
  RequireNewPassword as AmplifyRequireNewPassword,
} from "aws-amplify-react";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import Confirm from "./auth/Confirm";
import ResetPassword from "./auth/ResetPassword";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Layout from "./common/Layout";
import "./App.css";
import axios from "axios";
import LoadingIndicator from "./common/LoadingIndicator";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Auth } from "aws-amplify";
import {
  Home,
  Profile,
  Services,
  PersonalData,
  NextOfKin,
  Dependants,
  BillingData,
  BioData,
  MedicalQuestions,
  MedicalInformations,
  EmergencyContacts,
  PitCrew,
  NewRegistrationEntry,
  Registration,
  Redirection,
  RegistrationDetails,
  // Licences,
  LicenceDetails,
  NewLicence,
  // Machines,
  NewMachine,
  MachineDetails,
  Documentation,
  Results,
  Notifications,
  ContactForm,
  Paddock,
  HelpDesk,
  InvalidRouting
} from "./common/routes";
import { getTheme } from "./theme";

let FINAL_THEME;

class App extends Component {
  constructor(props) {
    super(props);
    // the actual interception
    axios.interceptors.request.use((req) => this.requestHandler(req));
    axios.interceptors.response.use(
      (res) => this.responseHandler(res),
      (err) => this.errorHandler(err)
    );
    this.state = {
      theme: getTheme(props.PRIMARY),
      loadCount: 0,
      open: false,
      message: { text1: "Unhandled operation", text2: null, emoji: "✖", link: null },
    };
    FINAL_THEME = createMuiTheme(this.state.theme);
  }

  componentDidUpdate(prevProps) {
    Auth.currentUserCredentials().catch((error) => {
      Auth.signOut();
    });
    if (this.props.PRIMARY !== prevProps.PRIMARY) {
      this.setState(
        { theme: getTheme(this.props.PRIMARY) },
        () => (FINAL_THEME = createMuiTheme(this.state.theme)) // Callback
      );
    }
  }

  // Request and response handlers
  requestHandler = (request) => {
    this.setState({ loadCount: this.state.loadCount + 1 });
    Auth.currentUserCredentials().catch((error) => {
      Auth.signOut().then(() => this.props.history.push("/"));
    });
    // We're intercepting so we have to return what we are intercepting 😂
    return request;
  };
  responseHandler = (response) => {
    if (
      response.config.method === "post" ||
      response.config.method === "put" ||
      response.config.method === "patch"
    ) {
      this.state.loadCount > 0 &&
        this.setState({
          message: {
            text1: "Operation successful!",
            text2: null,
            emoji: { symbol: "✔️", label: "green check mark icon" },
            link: null
          },
          open: true,
          loadCount: this.state.loadCount - 1,
        });
    }
    this.state.loadCount > 0 &&
      this.setState({ loadCount: this.state.loadCount - 1 });
    // We're intercepting so we have to return what we are intercepting
    return response;
  };
  // We're intercepting so we have to return what we are intercepting
  errorHandler = (error) => {
    this.state.loadCount > 0 &&
      this.setState({
        message: {
          text1: "Something is wrong",
          text2: "Please contact the ",
          emoji: { symbol: "❌", label: "red cross icon" },
          link: "Helpdesk"
        },
        open: true,
        loadCount: this.state.loadCount - 1,
      });
    // We're intercepting so we have to return what we are intercepting
    return error;
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setOpen(false);
  };
  setOpen = (s) => {
    this.setState({ open: s });
  };
  render() {
    return (
      <React.Fragment>
        {/* the loadCount represents the number of active requests that didn't get any response yet */}
        <LoadingIndicator loadCount={this.state.loadCount} />
        <BrowserRouter>
          <MuiThemeProvider theme={FINAL_THEME}>
            {/* the loadCount represents the number of active requests that didn't get any response yet */}
            <LoadingIndicator loadCount={this.state.loadCount} />
            {/* GLOBAL ERROR HANDLING */}
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={this.state.open}
              autoHideDuration={6000}
              onClose={this.handleClose}
              message={
                <span>
                  {this.state.message.text1}
                  <span role="img" aria-label={this.state.message.emoji.label}>
                    {this.state.message.emoji.symbol}
                  </span>
                  {this.state.message.text2 &&
                    <React.Fragment>
                      <br />
                      {this.state.message.text2}
                      {this.state.message.link &&
                        <a
                          style={{
                            color: this.state.theme.palette.primary.main,
                            wordBreak: "break-word",
                            textDecoration: 'none',
                            '&:visited': {
                              color: this.state.theme.palette.primary.main
                            }
                          }}
                          href={'/helpdesk'}
                          rel="noopener noreferrer">
                          {this.state.message.link}
                        </a>}
                    </React.Fragment>}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  style={{ padding: 8 }}
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
            <Layout location={window.location.pathname} logo={this.props.logo}>
              <Suspense fallback={<LoadingIndicator />}>
                <Switch>
                  <Route path="/home" exact component={Home} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/personalData" exact component={PersonalData} />
                  <Route path="/nextOfKin" exact component={NextOfKin} />
                  <Route path="/dependants" exact component={Dependants} />
                  <Route path="/emergencyContacts" exact component={EmergencyContacts} />
                  <Route path="/billingData" exact component={BillingData} />
                  <Route path="/bioData" exact component={BioData} />
                  <Route path="/medical/medicalQuestions" exact component={MedicalQuestions} />
                  <Route path="/medical/medicalInformations" exact component={MedicalInformations} />
                  <Route path="/pitCrew" exact component={PitCrew} />
                  <Route path="/confirmation" exact component={Confirm} />
                  <Route path="/signup" exact component={SignUp} />
                  <Route path="/services" component={Services} />
                  <Route path="/registration/new" exact component={NewRegistrationEntry} />
                  <Route path="/" exact component={Registration} />
                  <Route path="/redirection" exact component={Redirection} />
                  <Route path="/registration/details" exact component={RegistrationDetails} />
                  {/* <Route path="/licences" exact component={Licences} /> */}
                  <Route path="/licences/new" exact component={NewLicence} />
                  <Route path="/licences/details" exact component={LicenceDetails} />
                  {/* <Route path="/vehicles" exact component={Machines} /> */}
                  <Route path="/vehicles/new" exact component={NewMachine} />
                  <Route path="/vehicles/details" exact component={MachineDetails} />
                  <Route path="/documentation" exact component={Documentation} />
                  <Route path="/paddock" exact component={Paddock} />
                  <Route path="/results" component={Results} />
                  <Route path="/notifications" component={Notifications} />
                  <Route path="/contactForm" component={ContactForm} />
                  <Route path="/helpDesk" exact component={HelpDesk} />
                  <Route path="/*" component={InvalidRouting} />
                </Switch>
              </Suspense>
            </Layout>
          </MuiThemeProvider>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}
export default withAuthenticator(
  App,
  false,
  [
    <SignUp />,
    <SignIn />,
    <Confirm />,
    <AmplifyConfirmSignIn />,
    <AmplifyVerifyContact />,
    <ResetPassword />,
    //<AmplifyForgotPassword />,
    <AmplifyRequireNewPassword />,
  ],
  null,
  FINAL_THEME
);
